const initialsUpperCase = (string) => {
  const initialRegex = /(^\w{1})|(\s+\w{1})/g;
  if (string) {
    string = string.toLowerCase();
    return string.replace(initialRegex, (initial) => initial.toUpperCase());
  }
  return '';
}

const startUpperCase = (string) => {
  if (string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
}
const cutToTwoWords = (str) => {
  const words = str.split(' ');
  return words.slice(0, 2).join(' ');
};

export { initialsUpperCase, startUpperCase, cutToTwoWords };
