import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { Card, Button, Col, Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { initialsUpperCase, startUpperCase } from 'utils/textFormat';
import { useDispatch, useSelector } from 'react-redux';
// reducers
import { clearSenderForm } from '../../../slices/shipping/senderDataSlice';
import { clearReceiverForm } from '../../../slices/shipping/receiverDataSlice';
import { clearParcelForm } from '../../../slices/shipping/parcelDataSlice';
import { setLabeIdForPickup } from '../../../slices/shipping/shippingDataSlice';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});
const Documentation = () => {
  const title = 'Documentación';
  const description = 'Documentación Plataforma Autopaquete';
  const dispatch = useDispatch();
  const history = useHistory();
  // - Global State from Store.js form Values
  const { shippingData } = useSelector((state) => state.shipping);
  const { parcelData } = useSelector((state) => state.shipping_parcel);
  // console.log('data', shippingData);

  const fullAddressFrom = `${shippingData.address_from?.street} ${shippingData.address_from?.street_number}, ${shippingData.address_from?.neighborhood}, ${shippingData.address_from?.zip_code}, ${shippingData.address_from?.city}, ${shippingData.address_from?.state}.`;
  const fullAddressTo = `${shippingData.address_to?.street} ${shippingData.address_to?.street_number}, ${shippingData.address_to?.neighborhood}, ${shippingData.address_to?.zip_code}, ${shippingData.address_to?.city}, ${shippingData.address_to?.state}.`;
  const underscoresRegex = /_+/g;
  // const textFormat = (string) => {
  // const initialRegex = /(^\w{1})|(\s+\w{1})/g;
  //   return string.replace(initialRegex, (initial) => initial.toUpperCase());
  // };
  const goShippingForm = () => {
    // - Clear Form
    dispatch(clearSenderForm());
    dispatch(clearReceiverForm());
    dispatch(clearParcelForm());
    // history.push('/envios/enviar');
  };
  const goPickup = (labelId) => {
    history.push('/envios/recolectar/programar');
    dispatch(setLabeIdForPickup(labelId));
  };
  useEffect(() => {
    if (Object.keys(shippingData).length === 0) {
      history.push('/envios/enviar');
    }
  }, [history, shippingData]);
  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/envios/enviar" onClick={goShippingForm}>
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Enviar</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}
      <Row>
        <Col xs="12" className="col-lg order-1 order-lg-1">
          {/* Items Start */}
          <Row className="g-2 mb-1 order-1 order-lg-0">
            <h2 className="small-title">Instrucciones</h2>
            <Col sm="6" md="8">
              <Card className="sh-30 sh-sm-45 hover-img-scale-up mb-4">
                <Badge bg="primary" className="me-1 position-absolute e-2 t-2 z-index-1">
                  1
                </Badge>
                <img src="/img/autopaquete/enviar/btn-label.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div>
                    <div className="cta-1 mb-3 text-black w-md-100 w-75">Tu envío se genero exitosamente</div>
                    <div className="w-50 text-black d-none d-md-block">Imprime y pega este documento en tu paquete.</div>
                  </div>
                  <div>
                    <a
                      href={`data:application/pdf;base64,${shippingData?.label}`}
                      // download="guia.pdf"
                      download={`${shippingData?.shipping_label_id}.pdf`}
                      className="btn btn-icon btn-icon-start btn-primary mt-3 stretched-link"
                    >
                      <CsLineIcons icon="arrow-end-bottom" /> <span>Descargar etiqueta</span>
                    </a>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="6" md="4">
              <Card className="sh-30 sh-sm-45 hover-img-scale-up mb-4">
                <Badge bg="primary" className="me-1 position-absolute e-2 t-2 z-index-1">
                  2
                </Badge>
                <img src="/img/autopaquete/enviar/btn-recolection.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="cta-1 mb-3 text-black w-md-100 w-75">Entrega tu paquete en sucursal</div>
                  <div>
                    <a>
                      <Button
                        variant="outline-primary"
                        className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
                        onClick={() => {
                          window.open(
                            `https://www.google.com/maps/search/${shippingData?.shipping_company}+cerca+de+${shippingData.address_from?.city}+${shippingData?.address_from.state}`
                          );
                        }}
                      >
                        <CsLineIcons icon="chevron-right" /> <span>Ver sucursales</span>
                      </Button>
                    </a>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="g-2 mb-5">
            <Col sm="6">
              <Card className="sh-22 sh-xl-19 hover-img-scale-up mb-4">
                <Badge bg="primary" className="me-1 position-absolute e-2 t-2 z-index-1">
                  3
                </Badge>
                <img src="/img/autopaquete/enviar/btn-van.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="cta-3 mb-3 text-black w-75 w-md-50">¿No puedes ir a la sucursal?</div>
                  <div>
                    {/* <NavLink to="/envios/recolectar/programar" className="btn btn-icon btn-icon-start btn-outline-primary stretched-link" >
                      <CsLineIcons icon="chevron-right" /> <span>Programar recoleccion</span>
                    </NavLink> */}
                    <a>
                      <Button
                        variant="outline-primary"
                        className="btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto"
                        onClick={() => goPickup(shippingData?.shipping_label_id)}
                      >
                        <CsLineIcons icon="chevron-right" /> <span>Programar recolección</span>
                      </Button>
                    </a>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="sh-22 sh-xl-19 hover-img-scale-up mb-4">
                <Badge bg="primary" className="me-1 position-absolute e-2 t-2 z-index-1">
                  4
                </Badge>
                <img src="/img/autopaquete/enviar/button-support.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="cta-3 mb-3 text-black w-75 w-md-50">¿Dudas o aclaraciones?</div>
                  <div>
                    <NavLink to="/ayuda/soporte" className="btn btn-icon btn-icon-start btn-outline-primary stretched-link">
                      <CsLineIcons icon="chevron-right" /> <span>Soporte logístico</span>
                    </NavLink>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <div className="order-0 order-lg-1">
            <h2 className="small-title">Detalles servicio</h2>
            <Row className="g-2 row-cols-2 row-cols-md-3 row-cols-xl-6 mb-5">
              <Col className="sh-19">
                <Card className="h-100">
                  <img id={shippingData?.id} src={shippingData?.service_img} className="card-img h-100 scale" alt={shippingData?.shipping_company} />
                </Card>
              </Col>
              <Col className="sh-19">
                <Card className="h-100">
                  <Card.Body className="text-center px-0">
                    <CsLineIcons icon="plane" className="text-primary" />
                    <p className="heading mt-3 text-body">SERVICIO</p>
                    <div className="text-medium fw-medium text-muted">{shippingData.shipping_code?.replace(underscoresRegex, ' ')}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="sh-19">
                <Card className="h-100">
                  <Card.Body className="text-center px-0">
                    <CsLineIcons icon="pin" className="text-primary" />
                    <p className="heading mt-3 text-body">ZONA</p>
                    <div className="text-medium fw-medium text-muted">{shippingData?.extended_area ? 'EXTENDIDA' : 'NORMAL'}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="sh-19">
                <Card className="h-100">
                  <Card.Body className="text-center px-0">
                    <CsLineIcons icon="hospital" className="text-primary" />
                    <p className="heading mt-3 text-body ">SEGURO</p>
                    <div className="text-medium fw-medium text-muted">{shippingData.parcel?.insurance ? 'SI' : 'NO'}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="sh-19">
                <Card className="h-100">
                  <Card.Body className="text-center px-0">
                    <CsLineIcons icon="tag" className="text-primary" />
                    <p className="heading mt-3 text-body">COSTO</p>
                    <div className="text-medium fw-medium text-muted">{`$ ${fmDecimal.from(shippingData.shipping_rates?.total)}`}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="sh-19">
                <Card className="h-100">
                  <Card.Body className="text-center px-0">
                    <CsLineIcons icon="dollar" className="text-primary" />
                    <p className="heading mt-3 text-body">SALDO</p>
                    <div className="text-medium fw-medium text-muted">{`${fmDecimal.from(shippingData?.current_balance, { symbol: '$' })}`}</div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>

          <Row className="order-2 order-lg-2">
            <h2 className="small-title">Generar otro envío</h2>
            <Col sm="6">
              <Card className="sh-22 sh-xl-19 hover-img-scale-up mb-4">
                <img src="/img/autopaquete/enviar/btn-boxes.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="cta-3 mb-3 text-black w-75 ">¿Quieres repetir envío?</div>
                  <div>
                    <NavLink to="/envios/enviar/servicios" className="btn btn-icon btn-icon-start btn-outline-primary stretched-link w-md-auto ">
                      <CsLineIcons icon="chevron-right" /> <span>Ver servicios disponibles</span>
                    </NavLink>
                  </div>
                </div>
              </Card>
            </Col>
            <Col sm="6">
              <Card className="sh-22 sh-xl-19 hover-img-scale-up mb-4">
                <img src="/img/autopaquete/enviar/btn-form.webp" className="card-img h-100 scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="cta-3 mb-3 text-black w-75 ">¿Diferente información?</div>
                  <div>
                    {/* w-100 w-md-auto    w-75 w-md-50 */}
                    <a href="/envios/enviar">
                      <Button variant="outline-primary" className="btn-icon btn-icon-start ms-0 ms-sm-1 w-55 w-md-auto " onClick={goShippingForm}>
                        <CsLineIcons icon="chevron-right" /> <span>Ir a formulario</span>
                      </Button>
                    </a>
                    {/* <NavLink to="/envios/enviar" className="btn btn-icon btn-icon-start btn-outline-primary stretched-link" onClick={resetForm}>
                      <CsLineIcons icon="chevron-right" /> <span>Ir a formulario</span>
                    </NavLink> */}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs="12" lg="auto" className="order-0 order-lg-0">
          <h2 className="small-title">Detalles del envío</h2>
          <Card className="mb-5 w-100 sw-lg-35">
            <Card.Body>
              <div className="mb-6">
                <p className="text-small text-muted mb-2">DIRECCIÓN DE ORIGEN</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="user" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{initialsUpperCase(shippingData.address_from?.name)}</Col>
                </Row>
                {shippingData.address_from?.company && (
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="building" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(shippingData.address_from?.company)}</Col>
                  </Row>
                )}

                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="pin" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{initialsUpperCase(fullAddressFrom)}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="destination" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{startUpperCase(shippingData.address_from?.reference)}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="phone" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{shippingData.address_from?.phone}</Col>
                </Row>
                {/* <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="email" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{shippingData.address_from?.email}</Col>
                </Row> */}
              </div>
              <div className="mb-5">
                <p className="text-small text-muted mb-2">DIRECCIÓN DE DESTINO</p>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="user" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{initialsUpperCase(shippingData.address_to?.name)}</Col>
                </Row>
                {shippingData.address_to?.company && (
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="building" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(shippingData.address_to?.company)}</Col>
                  </Row>
                )}

                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="pin" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{initialsUpperCase(fullAddressTo)}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="destination" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{startUpperCase(shippingData.address_to?.reference)}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="phone" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{shippingData.address_to?.phone}</Col>
                </Row>
                {/* <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="email" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{shippingData.address_to?.email}</Col>
                </Row> */}
              </div>
              <div className="mb-5">
                <p className="text-small text-muted mb-2">PAQUETE</p>
                {parcelData.fields?.name && (
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="sign" size="17" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{startUpperCase(parcelData.fields?.name)}</Col>
                  </Row>
                )}

                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="edit-square" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">
                    {startUpperCase(shippingData.parcel?.product_description)}
                    {parcelData.fields?.product_value > 0 && (
                      <OverlayTrigger delay={{ show: 500, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Valor del contenido</Tooltip>}>
                        <span>{` - $${parcelData.fields?.product_value}`}</span>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="box" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${shippingData.parcel?.depth} x ${shippingData.parcel?.width} x ${shippingData.parcel?.height} - ${shippingData.parcel?.weight} kg`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="abacus" size="17" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${shippingData.parcel?.volumetric_weight} kg Volumétrico`}</Col>
                </Row>
                <Row className="g-0 mb-2">
                  <Col xs="auto">
                    <div className="sw-3 me-1">
                      <CsLineIcons icon="dollar" size="18" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="text-alternate">{`${shippingData.parcel?.total_weight} kg Total`}</Col>
                </Row>
                {shippingData.parcel?.multi_pieces > 1 && (
                  <Row className="g-0 mb-2">
                    <Col xs="auto">
                      <div className="sw-3 me-1">
                        <CsLineIcons icon="print" size="18" className="text-primary" />
                      </div>
                    </Col>
                    <Col className="text-alternate">{`Multi-piezas ${shippingData.parcel?.multi_pieces} `}</Col>
                  </Row>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Documentation;
