import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  parcelSelected: null,
  // editAddress: null,
};

const parcelSelectedSlice = createSlice({
  name: 'parcelSelected',
  initialState,
  reducers: {
    setParcelSelected(state, action) {
      state.parcelSelected = action.payload;
    },
  },
});

export const { setParcelSelected } = parcelSelectedSlice.actions; // imported in ----.js
const parcelSelectedReducer = parcelSelectedSlice.reducer; // store.js set up

export default parcelSelectedReducer;
