import React, { useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const LoadingPulse = () => {
  useEffect(() => {
    const contentArea = document.querySelector('#cardContainer');
    //  const htmlTag = document.documentElement;
    if (!contentArea) {
      // htmlTag.setAttribute('data-show', 'false');
    } else {
      contentArea.style = 'opacity:0.2';
      contentArea.style.pointerEvents = 'none';
    }
    //  document.body.classList.add('spinnerr');

    return () => {
      setTimeout(() => {
        if (!contentArea) {
          // htmlTag.setAttribute('data-show', 'true');
        } else {
          contentArea.style = 'opacity:1';
          contentArea.style.pointerEvents = 'auto';
        }
        // document.body.classList.remove('spinnerr');
      }, 30);
    };
  }, []);
  return (
    <div className="position-fixed top-50 start-50 translate-middle-x translate-middle-y">
      <ClipLoader color="var(--primary-text)" size="30px" />
    </div>
  );
};
export default LoadingPulse;
