import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, DatePickerModal, Application } from 'react-rainbow-components';
import { Form } from 'react-bootstrap';

import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';

const StyledButton = styled(Button)`
  padding: 10px;
  width: 274px;
  height: 36px;
  border: none !important;
  font-size: 1em !important;
`;
const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};

const formatDates = (dates) => {
  // console.log('fecha en modal:', dates);
  if (Array.isArray(dates) && dates.length > 1) {
    const startDay = new Intl.DateTimeFormat().format(dates[0]);

    if (dates.length > 1) {
      const endDay = new Intl.DateTimeFormat().format(dates[1]);

      return `${moment(startDay).format('DD-MM-YY')} - ${moment(endDay).format('DD-MM-YY')}`;
    }
    return startDay;
  }
  return 'Seleccionar fecha ';
};

const DateModal = (props) => {
  const { borderRadius, title, setStartDate, setEndDate, setRenderdate, renderDate, resetInitialValues } = props;
  const [isOpen, setOpen] = useState(false);
  const [dates, setDates] = useState();

  let startDate;
  let endDate;
  if (Array.isArray(dates) && dates.length > 1) {
    startDate = moment(dates[0]).format().slice(0, 10);
    endDate = moment(dates[1]).format().slice(0, 10);
  } else {
    startDate = '';
    endDate = '';
  }
  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setOpen(!isOpen);
    }
  }, [dates]);

  const removeFilter = () => {
    setRenderdate(!renderDate);
  };

  return (
    <>
      <StyledButton onClick={() => setOpen(!isOpen)} variant="border-filled" borderRadius={borderRadius} className="text-alternate">
        {formatDates(dates)}
      </StyledButton>
      {/* <Application theme={theme}></Application> */}
      <DatePickerModal
        title={title}
        isOpen={isOpen}
        value={dates}
        variant="double"
        selectionType="range"
        onChange={setDates}
        onRequestClose={() => setOpen(false)}
        borderRadius={borderRadius}
      />
    </>
  );
};
export default DateModal;
