import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
// import Clamp from 'components/clamp';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ClipLoader from 'react-spinners/ClipLoader';
import Loading from '../../../components/loading/LoadingRed';
// api
import { getZipCode, getRates } from '../../../api/Api-user';
// components
import SupplierCard from '../shipping/components/SupplierCard';

const Quote = () => {
  const title = 'Cotizar';
  const description = 'Cotizar Plataforma Autopaquete';
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // - Local States
  const [isVolumetricWeightOk, setIsVolumetricWeightOk] = useState('');
  const [isQuoterBtnDisabled, setIsQuoterBtnDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalWeight, setTotalWeight] = useState(null);
  const [dataRates, setDataRates] = useState({
    zipFrom: '',
    zipTo: '',
    stateFrom: { name: '', city: '' },
    stateTo: { name: '', city: '' },
    product_value_shipping: '',
    checkBoxSecure: '',
  });
  const [parcel, setParcel] = useState({
    height: '',
    width: '',
    depth: '',
    weight: '',
    product_value: 0,
  });

  const [dataRatesError, setDataRatesError] = useState({
    zipFrom: '',
    zipTo: '',
    parcel: false,
    height: false,
    width: false,
    depth: false,
    weight: false,
    product_value_shipping: false,
    checkBoxSecure: false,
  });
  const [result, setResult] = useState({});

  // - Data Validation and Updating
  const updateData = (type, data, validation) => {
    if (data === '' || validation.test(data)) setDataRates({ ...dataRates, [type]: data });
  };
  // - Data Validation and updating Parcel States
  const updateParcelData = (type, data, validation) => {
    if (data === '' || validation.test(data)) setParcel({ ...parcel, [type]: data * 1 });
  };

  // - Get Rates
  const handlerQuoter = () => {
    // dispatch(setRatesData({}));
    setResult({});
    setIsLoading(true);
    getRates(dataRates.zipFrom, dataRates.zipTo, parcel)
      .then((response) => {
        // console.log('1 then of handlerQuoter:', response.data.error.parcel);
        // console.log('then of handlerQuoter:', response);
        let msgErr = '';
        setIsLoading(false);
        if (response.zip_from !== undefined) {
          setResult(response);
          if (startRef.current) {
            startRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        } else if (response.data.error.parcel) {
          // to get this error don't sent parcel
          response.data.error.parcel.forEach((msg) => {
            msgErr = `Paquete: ${msg}. Código:  ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (response.data.error.rates) {
          // to get this error put 71 kg or oversizing
          response.data.error.rates.forEach((msg) => {
            msgErr = `${msg} Código:  ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (response.detail) {
          msgErr = response.detail;
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (response.error.zip_to) {
          msgErr = 'error cp';
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }

        // else if (response.error !== undefined) {
        //   let msgErr = '';
        //   const arrError = response.error.rates;
        //   arrError.forEach((error) => {
        //     msgErr = `${error} Código: ${response.error_code}`;
        //   });
        //   toast.error(msgErr, {
        //     position: 'top-right',
        //     autoClose: 5000,
        //     hideProgressBar: true,
        //     closeOnClick: false,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: 'dark',
        //   });
        // }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('catch of handlerQuoter:', error);
        toast.error('Error, intente nuevamente', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      });
  };

  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (dataRates.zipFrom.length === 5) {
      getZipCode(dataRates.zipFrom)
        .then((response) => {
          // console.log(response);
          if (response?.length > 0) {
            setDataRatesError({ ...dataRatesError, zipFrom: '' });
            setDataRates({ ...dataRates, stateFrom: { name: response[0].state.name, city: response[0].city } });
          } else {
            setDataRatesError({ ...dataRatesError, zipFrom: 'Código invalido' });
          }
        })
        .catch(() => {
          setDataRatesError({ ...dataRatesError, zipFrom: 'Sin conexión a Internet' });
        });
    } else {
      // when user is typing
      setDataRatesError({ ...dataRatesError, zipFrom: '' });
      setDataRates({ ...dataRates, stateFrom: { name: '', city: '' } });
    }
  }, [dataRates.zipFrom]);
  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (dataRates.zipTo.length === 5) {
      getZipCode(dataRates.zipTo)
        .then((response) => {
          if (response?.length > 0) {
            // dispatch(setDestinationData({ data: response[0].city, point: 'city' }));
            // dispatch(setDestinationData({ data: response[0].state, point: 'state' }));
            // dispatch(setDestinationDataError({ data: '', point: 'zipTo' }));
            setDataRatesError({ ...dataRatesError, zipTo: '' });
            setDataRates({ ...dataRates, stateTo: { name: response[0].state.name, city: response[0].city } });
          } else {
            setDataRatesError({ ...dataRatesError, zipTo: 'Código invalido' });
          }
        })
        .catch((error) => {
          setDataRatesError({ ...dataRatesError, zipTo: 'Sin conexión a Internet' });
        });
    } else {
      // when user is typing
      setDataRatesError({ ...dataRatesError, zipTo: '' });
      setDataRates({ ...dataRates, stateTo: { name: '', city: '' } });
    }
  }, [dataRates.zipTo]);

  useEffect(() => {
    // useEffect check changes on depth, width and height to calculate volumetric weight
    // parcel: { height: '', width: '', depth: '', weight: '', product_value: 0 },
    if (parcel.depth && parcel.width && parcel.height) {
      setIsVolumetricWeightOk(Math.ceil((parcel.height * parcel.width * parcel.depth) / 5000));
    } else {
      setIsVolumetricWeightOk('');
    }
  }, [parcel.depth, parcel.width, parcel.height]);
  useEffect(() => {
    // useEffect set the product value depending of checkbox value
    if (!dataRates.checkBoxSecure) {
      // console.log('checkbox falso el valor del estado debe ser 0 :', packageData.fields.parcel.product_value);
      // updateData(setParcel, 0, 'product_value', /^[0-9]+$/i);
      // dispatch(setPackageDataError({ data: '', point: 'parcel' }));
      setParcel({ ...parcel, product_value: 0 });
      setDataRatesError({ ...dataRatesError, parcel: false });
    } else {
      // console.log('checkbox true el valor del estado debe ser el valor en product value shipping:', packageData.fields.product_value_shipping);
      updateParcelData('product_value', dataRates.product_value_shipping, /^[0-9]+$/i);

      if (!dataRates.product_value_shipping) {
        // dispatch(setPackageDataError({ data: 'Ingrese valor del contenido', point: 'parcel' }));
        setDataRatesError({ ...dataRatesError, parcel: true });
      } else {
        // dispatch(setPackageDataError({ data: '', point: 'parcel' }));
        setDataRatesError({ ...dataRatesError, parcel: false });
      }
    }
  }, [dataRates.checkBoxSecure, dataRates.product_value_shipping]);

  useEffect(() => {
    // useEffect change disabled quoter button
    // console.log('BOTON HABILITADO :', parcel.product_value, dataRates.product_value_shipping, !dataRates.checkBoxSecure);
    if (dataRates.stateFrom.city && dataRates.stateTo.city && isVolumetricWeightOk && parcel.weight && !dataRates.checkBoxSecure) {
      setIsQuoterBtnDisabled(false);
    } else if (dataRates.checkBoxSecure && !parcel.product_value) {
      setIsQuoterBtnDisabled(true);
    } else if (dataRates.checkBoxSecure && parcel.product_value > 1) {
      setIsQuoterBtnDisabled(false);
    } else {
      setIsQuoterBtnDisabled(true);
    }
  }, [dataRates.stateFrom.city, dataRates.stateTo.city, isVolumetricWeightOk, parcel.weight, dataRates.checkBoxSecure, parcel.product_value]);
  // useEffect calculates the total weight
  useEffect(() => {
    if (isVolumetricWeightOk && parcel.weight) {
      setTotalWeight(Math.max(isVolumetricWeightOk, parcel.weight));
    } else {
      setTotalWeight(null);
    }
  }, [parcel.weight, isVolumetricWeightOk]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Inicio</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End */}
      <Row>
        <Col xs="12" lg="10">
          <h2 className="small-title">Detalles del envío</h2>
          <Card className="mb-4 position-relative">
            <Card.Body>
              <Form autoComplete="off" autoFill="off">
                <Row className="g-3">
                  <Col xs="12" sm="6" lg="3" className="position-relative">
                    <Form.Label>Origen</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1">
                      <InputGroup.Text id="input-origen">
                        <CsLineIcons icon="pin" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        // placeholder="Código postal"
                        // aria-label="Origen t"
                        // aria-describedby="input-origen"
                        // type="text"
                        // required
                        autoComplete="off"
                        autoFill="off"
                        maxLength={5}
                        value={dataRates.zipFrom}
                        onChange={(e) => updateData('zipFrom', e.target.value, /^[0-9]+$/i)}
                      />
                    </InputGroup>
                    <small className="form-text text-primary">{dataRates.stateFrom.city && `${dataRates.stateFrom.city}, ${dataRates.stateFrom.name}.`}</small>
                    {dataRatesError.zipFrom && (
                      <div className={`invalid-tooltip ${dataRatesError.zipFrom ? 'd-block' : 'd-none'}`}>{dataRatesError.zipFrom}</div>
                    )}
                  </Col>
                  <Col xs="12" sm="6" lg="3" className="position-relative">
                    <Form.Label>Destino</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1">
                      <InputGroup.Text id="input-destino">
                        <CsLineIcons icon="arrow-right" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        // aria-label="Destino t"
                        // aria-describedby="input-destino"
                        // type="text"
                        // required
                        autoComplete="off"
                        autofill="off"
                        maxLength={5}
                        value={dataRates.zipTo}
                        onChange={(e) => updateData('zipTo', e.target.value, /^[0-9]+$/i)}
                      />
                    </InputGroup>
                    <small className="form-text text-primary">{dataRates.stateTo.city && `${dataRates.stateTo.city}, ${dataRates.stateTo.name}.`}</small>
                    {dataRatesError.zipTo && <div className={`invalid-tooltip ${dataRatesError.zipTo ? 'd-block' : 'd-none'}`}>{dataRatesError.zipTo}</div>}
                  </Col>
                  <Col xs="12" sm="8" lg="4">
                    <Form.Label>Dimensiones</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1">
                      <InputGroup.Text id="input-largo">
                        <CsLineIcons icon="box" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Largo cm"
                        maxLength={3}
                        // aria-label="Largo"
                        // aria-describedby="input-largo"
                        // type="text"
                        // required
                        value={parcel.depth}
                        onChange={(e) => updateParcelData('depth', e.target.value, /^[0-9]*$/)}
                      />
                      <InputGroup.Text id="input-ancho">
                        <CsLineIcons icon="multiply" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Ancho cm"
                        maxLength={3}
                        aria-label="Ancho"
                        aria-describedby="input-ancho"
                        type="text"
                        // required
                        value={parcel.width}
                        onChange={(e) => updateParcelData('width', e.target.value, /^[0-9]*$/)}
                      />
                      <InputGroup.Text id="input-alto">
                        <CsLineIcons icon="multiply" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="Alto cm"
                        maxLength={3}
                        aria-label="Alto"
                        aria-describedby="input-alto"
                        type="text"
                        // required
                        value={parcel.height}
                        onChange={(e) => updateParcelData('height', e.target.value, /^[0-9]*$/)}
                      />
                    </InputGroup>
                    <small className="form-text text-primary">{isVolumetricWeightOk && `Peso volumetrico: ${isVolumetricWeightOk} kg.`}</small>
                  </Col>
                  {/*  */}
                  <Col xs="12" sm="3" md="3" lg="2">
                    <Form.Label>Peso</Form.Label>
                    <span className="text-primary">*</span>
                    <InputGroup className="mb-1" style={{ maxWidth: '120px' }}>
                      <InputGroup.Text id="input-peso">
                        <CsLineIcons icon="download" size="17" className="text-primary" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="kg"
                        maxLength={3}
                        // aria-label="Destino"
                        aria-describedby="input-peso"
                        // required
                        value={parcel.weight}
                        onChange={(e) => updateParcelData('weight', e.target.value, /^[0-9]*$/)}
                      />
                    </InputGroup>
                    <small className="form-text text-primary">{totalWeight && `Peso a cobrar: ${totalWeight} kg.`}</small>
                  </Col>
                  {/* style={{backgroundColor:"white"}} bg-tertiary backgroundColor:"red",   bg-secondary  md="3" lg="2" */}
                  <Col xs="12" sm="auto">
                    <div className="d-flex flex-column">
                      <div>
                        <label className="form-label">¿Desea asegurar?</label>
                      </div>
                      <div className="">
                        <input
                          type="checkbox"
                          checked={dataRates.checkBoxSecure && 'checked'}
                          // className="form-check-input me-1 me-sm-2"
                          className="ms-2 form-check-input "
                          // name="terms"
                          onClick={(e) => {
                            setDataRates({ ...dataRates, checkBoxSecure: e.target.checked });
                            // dispatch(setPackageData({ point: 'checkBoxSecure', data: e.target.checked }));
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  {/* style={{ position: 'relative' }} lg="3" xl="2" style={{ background: 'pink' }}  className="text-primary"  className="position-relative d-flex flex-column justify-content-end" */}
                  <Col xs="10" sm="4" md="3" className="position-relative">
                    <Form.Label>Valor del contenido</Form.Label>
                    <InputGroup className="mb-1">
                      <InputGroup.Text id="input-valor">
                        <CsLineIcons icon="dollar" size="17" />
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="MXN"
                        aria-label="Valor del contenido"
                        aria-describedby="input-valor"
                        disabled={!dataRates.checkBoxSecure}
                        type="text"
                        value={dataRates.product_value_shipping}
                        style={{ maxWidth: '250px' }}
                        onChange={(e) => {
                          updateData('product_value_shipping', e.target.value, /^[0-9]*$/);
                          // updateData('product_value', e.target.value, /^[0-9]+$/i);
                          setParcel({ ...parcel, product_value: e.target.value });
                        }}
                      />
                    </InputGroup>
                    {dataRatesError.parcel && (
                      <div className={`invalid-tooltip ${dataRatesError.parcel ? 'd-block' : 'd-none'}`}>Ingrese valor del contenido</div>
                    )}
                  </Col>
                  {/* className="d-lg-flex justify-content-center" style={{backgroundColor:"pink"}} mx-auto lg="5" */}
                  {/* <Col lg="6" className="mt-xs-0 d-md-none d-lg-none" ms-lg-5 /> */}
                  <Col xs="12" sm="4" lg="3" className="d-flex align-items-end">
                    <InputGroup className="mb-1">
                      <Button
                        variant="primary"
                        className="btn btn-icon btn-icon-end w-100"
                        disabled={isQuoterBtnDisabled}
                        onClick={handlerQuoter}
                        // style={{ maxWidth: '300px' }}
                      >
                        Cotizar
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Form>
              {isLoading && (
                <div
                  className="position-absolute rounded top-0 start-0 d-flex justify-content-center align-items-center"
                  style={{ height: ' 100%', width: '100%', backgroundColor: '#ffffff86' }}
                >
                  <Loading />
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <h2 className="small-title" ref={startRef}>
          Servicios disponibles
        </h2>
        {result.rates !== undefined && (
          <Col xs="12" lg="10">
            {/* Items Start */}
            {/* <h2 className="small-title">Servicios disponibles</h2> */}
            <div className="mb-5">
              {result.rates?.map((rate) => (
                <SupplierCard key={rate.shipping_code} rate={rate} page="quote" />
              ))}
            </div>
            {/* Items End */}
          </Col>
        )}
      </Row>
    </>
  );
};

export default Quote;
