import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardData: {
    fields: {
      currentBalance: 0,
      stpAccount: 0,
      qtyShipments: 0,
      qtyPickups: 0,
      qtySessions: 0,
      qtyErr: 0,
      lastShippings: [],
    },
  },
};
const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    setDashboardData(state, action) {
      const { point, data } = action.payload;
      state.dashboardData.fields[point] = data;
    },
  },
});
export const { setDashboardData } = dashboardDataSlice.actions; // imported in dashboard.js
const dashboardDataReducer = dashboardDataSlice.reducer; // store.js setup
export default dashboardDataReducer;
