import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';

// External libraries
// import moment from 'moment';
// import CsLineIcons from 'cs-line-icons/CsLineIcons';
// import BillsCSV from '../../../../components/reactCsv/BillsCSV';
// api
import { getBillingDocs } from '../../../../../api/Api-admin';
// components
import Paginate from '../../../../../components/Paginate';
import BillingRow from '../components/BillingRow';
import BillingCard from '../components/BillingCard';
import LoadingPulse from '../../../../../../components/loading/LoadingPulse';

function Invoices({ crmId }) {
  const [invoices, setInvoices] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // paginate
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  // - Use references to do scroll Up
  const startRef = useRef(null);
  useEffect(() => {
    const handlerGetInvoices = () => {
      setIsLoading(true);
      getBillingDocs('invoices', crmId, currentPage, pageSize)
        .then((response) => {
          console.log('Get invoices response', response);
          setIsLoading(false);
          setInvoices(response.data);
          setTotalPages(response.total_pages);
          if (startRef.current) {
            startRef.current.scrollIntoView({ behavior: 'smooth' });
          }
        })
        .catch(() => {
          // console.log('Err catch get invoices', err);
        });
    };
    handlerGetInvoices();
  }, [currentPage, pageSize]);

  const settingPage = (limit) => {
    setIsLoading(true);
    setPageSize(limit);
    setCurrentPage(1);
  };
  // console.log(invoices)
  return (
    <>
      {isLoading && <LoadingPulse />}
      {invoices?.length === 0 ? (
        <div className="pt-5 mt-5">
          <h3 className="text-center mt-5 pt-5">
            No hay facturas registradas.
            <img alt="box" src="/img/animation/doc.webp" style={{ width: '50px', height: '50px' }} />{' '}
          </h3>
        </div>
      ) : (
        <div ref={startRef} id="cardContainer">
          <Row className="g-0">
            {/* Dropdown Start */}
            <Col className="text-end">
              {/* {invoices?.length > 0 && <BillsCSV invoices={invoices} />} */}
              <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${invoices?.length === 0 && 'invisible'}`}>
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-15">
                  {`${pageSize} Facturas`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Facturas
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Facturas
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Facturas
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Facturas
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={`text-muted d-lg-none my-3 ${invoices?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div>
              {/* Length End */}
            </Col>
            {/* Dropdown Start */}
          </Row>
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <Col xs="auto" className={`text-muted mb-3 ${invoices?.length === 0 && 'invisible'}`}>
              {`Página ${currentPage}`}
            </Col>
          </Row>
          {/* List Header Stat */}
          <BillingRow />
          {/* List Header End */}
          {/* List Items Start */}
          {invoices?.map((invoice, index) => (
            <BillingCard data={invoice} key={index} crmId={crmId} doc="Factura" />
          ))}
          {/* List Items End */}
          {/* Pagination Start */}
          <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
            <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
          </div>
          {/* Pagination End */}
        </div>
      )}
    </>
  );
}

export default Invoices;
