import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Row, Col, Button, Card, Form } from 'react-bootstrap';
import { Modal, Application } from 'react-rainbow-components';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// External libraries
import * as Yup from 'yup';
// api
import { editParcel, deleteParcel } from '../../../../api/Api-user';

const ModalParcel = ({ setIsModalOpen }) => {
  // - Global State from Store.js form Values
  const { parcelSelected } = useSelector((state) => state.parcel_data);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);

  // console.log(parcelSelected);
  let initialLetters = '';
  const words = parcelSelected.name.split(' ');
  words.forEach((word) => {
    initialLetters += word.charAt(0); // Obtener la primera letra de cada palabra y agregarla a las iniciales
  });
  const theme = {
    rainbow: {
      palette: {
        brand: '#850000',
      },
    },
  };
  // - Local State
  const [parcel, setParcel] = useState({
    name: parcelSelected.name,
    product_description: parcelSelected.product_description,
    height: parcelSelected.height,
    width: parcelSelected.width,
    depth: parcelSelected.depth,
    weight: parcelSelected.weight,
    product_value: parcelSelected.product_value ? parcelSelected.product_value : 0,
    insurance: parcelSelected.insurance,
  });
  // - Local State
  const [parcelErr, setParcelErr] = useState({
    name: '',
    product_description: '',
    height: '',
    width: '',
    depth: '',
    weight: '',
  });
  // - Data Validation and Updating States with Reducers
  const updateField = (data, validation, field) => {
    if (data === '' || validation.test(data)) setParcel({ ...parcel, [field]: data });
  };
  // Schema Validation
  const validationParcelSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    product_description: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    height: Yup.string().required('Campo requerido'),
    width: Yup.string().required('Campo requerido'),
    depth: Yup.string().required('Campo requerido'),
    weight: Yup.string().required('Campo requerido'),
  });
  const handlerEditParcel = () => {
    // console.log('from ok parcel seleccionado ', parcel, parcelSelected.id);
    editParcel(parcelSelected.id, parcel)
      .then((response) => {
        // console.log("response ok:", response)
        if (response.name !== undefined) {
          setIsModalOpen(false);
          toast.success('El paquete ha sido editado correctamente.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (response.data.error.address) {
          // setIsModalOpen(false)
          let msgErr = ' ';
          const arrError = response.data.error.address;
          arrError.forEach((error) => {
            msgErr = `${error} Código: ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (response.data.error.parcels) {
          // setIsModalOpen(false)
          let msgErr = ' ';
          const arrError = response.data.error.parcels;
          arrError.forEach((error) => {
            msgErr = `${error} Código: ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      })
      .catch((err) => console.log(err));
  };
  const handlerDeleteParcel = () => {
    deleteParcel(parcelSelected.id)
      .then((response) => {
        console.log('response delete:', response);
        setIsModalDeleteOpen(false);
        setIsModalOpen(false);
        if (response.is_deleted) {
          setIsModalOpen(false);
          toast.success('¡Se elimino correctamente!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else {
          toast.error('Error: No se ha eliminado el paquete. Por favor, inténtelo nuevamente. ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      })
      .catch(() => {
        toast.error('Error: No se ha eliminado el paquete. Por favor, inténtelo nuevamente. ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  };

  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationParcelSchema.validate(parcel, { abortEarly: false });
      handlerEditParcel();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        setParcelErr({ ...parcelErr, [error.path]: error.message });
        formErrors[error.path] = error.message;
      });
      // console.log('errores en from:', formErrors);
      // formAddressFromRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
  };

  return (
    <>
      <Application theme={theme}>
        <Modal
          isOpen={isModalDeleteOpen}
          onRequestClose={() => setIsModalDeleteOpen(false)}
          borderRadius="rounded"
          title=" "
          footer={
            <div className="d-flex justify-content-end ">
              <Button variant="outline-primary" className="me-2" onClick={() => handlerDeleteParcel()}>
                Si, Eliminar
              </Button>
              <Button variant="primary" onClick={() => setIsModalDeleteOpen(false)}>
                Cancelar
              </Button>
            </div>
          }
        >
          <h2 className="fs-6 text-center my-4">¿Está seguro de eliminar este paquete?</h2>
        </Modal>
      </Application>
      <Card className="mb-5">
        <Card.Body className="mb-n7">
          <div className="d-flex align-items-center flex-column">
            <div className="d-flex align-items-center flex-column">
              <div className="sw-6 sh-6 mb-3 d-inline-block bg-primary d-flex justify-content-center align-items-center rounded-xl">
                <div className="text-white">{initialLetters.substring(0, 2)}</div>
              </div>
              <div className="h5 mb-1">{parcelSelected.name}</div>
              {/* <div className="text-muted">
                    <CsLineIcons icon="pin" className="me-1" />
                    <span className="align-middle">Guadalajara, Mexico</span>
                  </div> */}
            </div>
          </div>
          <div className="mb-5">
            <Row>
              <Col>
                <div className="mb-5">
                  <Form onSubmit={validateForm}>
                    <div className="my-5 d-flex flex-row justify-content-between w-100">
                      <Button className="w-100 me-2" variant="outline-primary" onClick={() => setIsModalDeleteOpen(true)}>
                        Eliminar
                        <CsLineIcons icon="bin" size="17" className="primary ms-2" />
                      </Button>
                      <Button className="w-100 me-2" type="submit">
                        Guardar
                        <CsLineIcons icon="save" size="17" variant="primary" className="primary ms-2" />
                      </Button>
                    </div>
                    <Row className="g-3">
                      <Col sm="12">
                        <Form.Label>Nombre</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            onChange={(e) => {
                              setParcelErr({ ...parcelErr, name: '' });
                              updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'name');
                            }}
                            value={parcel.name}
                            type="text"
                          />
                          {parcelErr.name && <div className={`invalid-tooltip ${parcelErr.name ? 'd-block' : 'd-none'}`}>{parcelErr.name}</div>}
                        </div>
                      </Col>
                      <Col sm="12">
                        <Form.Label>Descripción</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            onChange={(e) => {
                              setParcelErr({ ...parcelErr, product_description: '' });
                              updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'product_description');
                            }}
                            value={parcel.product_description}
                            type="text"
                          />
                          {parcelErr.product_description && (
                            <div className={`invalid-tooltip ${parcelErr.product_description ? 'd-block' : 'd-none'}`}>{parcelErr.product_description}</div>
                          )}
                        </div>
                      </Col>
                      <Col sm="12">
                        <Form.Label>Largo</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            maxLength={3}
                            onChange={(e) => {
                              setParcelErr({ ...parcelErr, depth: '' });
                              updateField(e.target.value, /^[0-9]*$/, 'depth');
                            }}
                            value={parcel.depth}
                            type="text"
                          />
                          {parcelErr.depth && <div className={`invalid-tooltip ${parcelErr.depth ? 'd-block' : 'd-none'}`}>{parcelErr.depth}</div>}
                        </div>
                      </Col>
                      <Col sm="12">
                        <Form.Label>Ancho</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            maxLength={3}
                            onChange={(e) => {
                              setParcelErr({ ...parcelErr, width: '' });
                              updateField(e.target.value, /^[0-9]*$/, 'width');
                            }}
                            value={parcel.width}
                            type="text"
                          />
                          {parcelErr.width && <div className={`invalid-tooltip ${parcelErr.width ? 'd-block' : 'd-none'}`}>{parcelErr.width}</div>}
                        </div>
                      </Col>
                      <Col sm="12">
                        <Form.Label>Alto</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            maxLength={3}
                            onChange={(e) => {
                              setParcelErr({ ...parcelErr, height: '' });
                              updateField(e.target.value, /^[0-9]*$/, 'height');
                            }}
                            value={parcel.height}
                            type="text"
                          />
                          {parcelErr.height && <div className={`invalid-tooltip ${parcelErr.height ? 'd-block' : 'd-none'}`}>{parcelErr.height}</div>}
                        </div>
                      </Col>
                      <Col sm="12">
                        <Form.Label>Peso</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            maxLength={2}
                            onChange={(e) => {
                              setParcelErr({ ...parcelErr, weight: '' });
                              updateField(e.target.value, /^[0-9]*$/, 'weight');
                            }}
                            value={parcel.weight}
                            type="text"
                          />
                          {parcelErr.weight && <div className={`invalid-tooltip ${parcelErr.weight ? 'd-block' : 'd-none'}`}>{parcelErr.weight}</div>}
                        </div>
                      </Col>
                      <Col sm="12">
                        <Form.Label>Valor del contenido</Form.Label>
                        <div style={{ position: 'relative', zIndex: 1 }}>
                          <Form.Control
                            maxLength={5}
                            onChange={(e) => {
                              updateField(e.target.value, /^[0-9]*$/, 'product_value');
                            }}
                            value={parcel.product_value}
                            type="text"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ModalParcel;
