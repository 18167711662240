import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Card, Form } from 'react-bootstrap';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRateList } from '../../api/Api-admin';

const Register = () => {
  const title = 'Registrar Usuario';
  const description = 'Registrar Usuario Plataforma Autopaquete';
  const history = useHistory();
  // Local State
  const [isAutoBalance, setIsAutoBalance] = useState(false);
  const [isMultiPieces, setIsMultiPieces] = useState(false);
  const [isAutoVouchers, setIsAutoVouchers] = useState(false);
  const [customerType, setCustomerType] = useState('');
  const [taxType, setTaxType] = useState('');
  const [seller, setSeller] = useState('');
  const [rateMethod, setRateMethod] = useState('');
  const [labelType, setLabelType] = useState('');
  const [ratesList, setRatesList] = useState([]);
  const [rate, setRate] = useState('');
  useEffect(() => {
    getRateList().then((response) => {
      console.log('get rates:', response);
      if (response.rates_list) {
        setRatesList(response.rates_list);
      } else {
        toast.error('Error: No fue posible obtener lista de tarifarios', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  }, []);
  const validateForm = () => {};
  return (
    <>
      {/* <i className="d-inline-block text-primary icon-20 bi-emoji-frown" */}
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <div className="primary-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer invisible" onClick={() => history.goBack()}>
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Atrás</span>
            </div>
            <h1 className="mb-5 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}
        </Row>
      </div>
      <Form onSubmit={validateForm}>
        {/* Title End */}
        <Row className="g-0">
          {/* Top Buttons */}
          <Col xs="12" sm="auto" lg="10" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0">
            <Button variant="primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
              <CsLineIcons icon="save" /> <span>Registrar</span>
            </Button>
          </Col>
          {/* Customer Info */}
          <Col xs="12" lg="10" className="col-lg">
            <h2 className="small-title">Información Personal</h2>
            <Card className="mb-5 bg-successx">
              <Card.Body>
                <Row>
                  <Row>
                    <Col xs="12" lg="6" className="mb-2">
                      <Form.Label>Correo</Form.Label>
                      <div className="filled form-group tooltip-end-top">
                        <CsLineIcons icon="email" />
                        <Form.Control type="text" />
                      </div>
                    </Col>
                    <Col xs="12" lg="6" className="mb-2">
                      <Form.Label>Contraseña</Form.Label>
                      <div className="filled form-group tooltip-end-top">
                        <CsLineIcons icon="lock-off" />
                        <Form.Control type="text" />
                      </div>
                    </Col>
                  </Row>
                  <Col xs="12" lg="2" className="mb-2">
                    <Form.Label>Crm id</Form.Label>
                    <div className="filled form-group tooltip-end-top">
                      <CsLineIcons icon="user" />
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs="12" lg="6" className="mb-2">
                    <Form.Label>Razón Social</Form.Label>
                    <div className="filled form-group tooltip-end-top position-relative d-flex align-items-center">
                      <CsLineIcons icon="building-small" />
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs="12" lg="3" className="mb-2">
                    <Form.Label>RFC</Form.Label>
                    <div className="filled form-group tooltip-end-top">
                      <CsLineIcons icon="invoice" />
                      <Form.Control type="text" />
                    </div>
                  </Col>
                  <Col xs="12" lg="6" className="mb-2">
                    <Form.Label>Telefono</Form.Label>
                    <div className="filled form-group tooltip-end-top">
                      <CsLineIcons icon="phone" />
                      <Form.Control type="text" />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/* Account Setup */}
          <Col xs="12" lg="10" className="col-lg">
            <h2 className="small-title">Configuración Cuenta</h2>
            <Card className="mb-5">
              <Card.Body>
                {/* fiscal info */}
                <Row>
                  <Col xs="12" lg="6" className="bg-warningx">
                    <Row>
                      <Col xs="12" lg="12" className="mb-2 position-relative">
                        <Form.Label>Correo</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="email" />
                          <Form.Control type="text" />
                        </div>
                      </Col>
                      <Col xs="12" lg="12" className="mb-2 position-relative">
                        <Form.Label>Código CP</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="pin" />
                          <Form.Control type="text" />
                        </div>
                      </Col>
                      <Col xs="12" lg="12" className="mb-2 pt-2">
                        <div className="d-flex align-items-center">
                          <CsLineIcons icon="suitcase" size="19" className="text-primary" />
                          <div className="text-alternate mx-2"> REGIMEN FISCAL </div>
                          <Dropdown>
                            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                              {taxType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow dropdown-menu-start">
                              <Dropdown.Item className={`${taxType === 'Ingresos por intereses' && 'd-none'}`} onClick={(e) => setTaxType(e.target.innerText)}>
                                INGRESOS POR INTERESES
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${taxType === 'Sin obligaciones fiscales' && 'd-none'}`}
                                onClick={(e) => setTaxType(e.target.innerText)}
                              >
                                {' '}
                                SIN OBLIGACIONES FISCALES
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>
                      <Col xs="12" lg="12" className="mb-2">
                        <div className="d-flex align-items-center">
                          <CsLineIcons icon="dollar" size="19" className="text-primary" />
                          <div className="text-alternate mx-2">SALDO AUTOMATICO</div>
                          <input
                            type="checkbox"
                            className="form-check-input cursor-pointer ms-2  mb-1"
                            checked={isAutoBalance}
                            onClick={() => setIsAutoBalance(!isAutoBalance)}
                          />
                        </div>
                      </Col>
                      <Col xs="12" lg="12" className="mb-2">
                        <div className="d-flex align-items-center">
                          <CsLineIcons icon="invoice" size="19" className="text-primary" />
                          <div className="text-alternate mx-2">COMPROBANTE AUTOMATICO</div>
                          <input
                            type="checkbox"
                            className="form-check-input cursor-pointer ms-2  mb-1"
                            checked={isAutoVouchers}
                            onClick={() => setIsAutoVouchers(!isAutoVouchers)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12" lg="5" className="bg-infox pt-4 ps-lg-5 ms-lg-5">
                    <Row>
                      <Col xs="12" lg="12" className="mb-2">
                        <div className="d-flex align-items-center mb-2">
                          <CsLineIcons icon="shield" size="19" className="text-primary" />
                          <div className="text-alternate mx-2"> CLIENTE TIPO </div>
                          <Dropdown>
                            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                              {customerType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow dropdown-menu-start">
                              <Dropdown.Item className={`${customerType === 'T' && 'd-none'}`} onClick={() => setCustomerType('T')}>
                                T
                              </Dropdown.Item>
                              <Dropdown.Item className={`${customerType === 'C' && 'd-none'}`} onClick={() => setCustomerType('C')}>
                                {' '}
                                C
                              </Dropdown.Item>
                              <Dropdown.Item className={`${customerType === 'D' && 'd-none'}`} onClick={() => setCustomerType('D')}>
                                {' '}
                                D{' '}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>
                      <Col xs="12" lg="12" className="mb-2 bg-infod">
                        <div className="d-flex align-items-center mb-2">
                          <CsLineIcons icon="support" size="19" className="text-primary" />
                          <div className="text-alternate mx-2"> ASESOR </div>
                          <Dropdown>
                            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                              {seller}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow dropdown-menu-start">
                              <Dropdown.Item className={`${seller === 'V000' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                                {' '}
                                V000
                              </Dropdown.Item>
                              <Dropdown.Item className={`${seller === 'V002' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                                {' '}
                                V002
                              </Dropdown.Item>
                              <Dropdown.Item className={`${seller === 'V003' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                                {' '}
                                V003{' '}
                              </Dropdown.Item>
                              <Dropdown.Item className={`${seller === 'V004' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                                {' '}
                                V004
                              </Dropdown.Item>
                              <Dropdown.Item className={`${seller === 'V005' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                                {' '}
                                V005{' '}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Col>
                      <Row>
                        <Col xs="12" lg="12" className="mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <CsLineIcons icon="code" size="19" className="text-primary" />
                            <div className="text-alternate mx-2"> METODO TARIFARIO </div>
                            <Dropdown>
                              <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                                {rateMethod}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="shadow dropdown-menu-start">
                                <Dropdown.Item className={`${rateMethod === 'API' && 'd-none'}`} onClick={(e) => setRateMethod(e.target.innerText)}>
                                  API
                                </Dropdown.Item>
                                <Dropdown.Item className={`${rateMethod === 'FIREBASE' && 'd-none'}`} onClick={(e) => setRateMethod(e.target.innerText)}>
                                  {' '}
                                  FIREBASE
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                        <Col xs="12" lg="12" className="mb-2">
                          <div className="d-flex align-items-center mb-2">
                            <CsLineIcons icon="star" size="19" className="text-primary" />

                            <div className="text-alternate mx-2"> TARIFARIO </div>
                            <Dropdown>
                              <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                                {rate}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="shadow dropdown-menu-start" style={{ height: '200px', overflowY: 'scroll' }}>
                                {ratesList?.map((rates) => (
                                  <Dropdown.Item key={rates.id} onClick={() => setRate(rates.name)}>
                                    {rates.name}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" lg="12" className="mb-2 bg-successx">
                          <div className="d-flex align-items-center mb-2">
                            <CsLineIcons icon="print" size="19" className="text-primary" />
                            <div className="text-alternate mx-2"> ETIQUETA </div>
                            <Dropdown>
                              <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                                {labelType}
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="shadow dropdown-menu-start">
                                <Dropdown.Item className={`${rateMethod === 'COMPACT' && 'd-none'}`} onClick={(e) => setLabelType(e.target.innerText)}>
                                  COMPACT
                                </Dropdown.Item>
                                <Dropdown.Item className={`${rateMethod === 'STANDARD' && 'd-none'}`} onClick={(e) => setLabelType(e.target.innerText)}>
                                  STANDARD
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Col>
                        <Col xs="12" lg="12" className="mb-2 bg-infox d-flex align-items-end pb-2">
                          <div className="d-flex align-items-center justify-content-end">
                            <CsLineIcons icon="duplicate" size="19" className="text-primary" />
                            <div className="text-alternate mx-2">MULTIPIEZAS</div>
                            <input
                              type="checkbox"
                              className="form-check-input cursor-pointer ms-2  mb-1"
                              checked={isMultiPieces}
                              onClick={() => setIsMultiPieces(!isMultiPieces)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Register;
