import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Application, FileSelector } from 'react-rainbow-components';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// api
import { createOverweightOrder } from '../../../api/Api-admin';

const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};
const OrderModal = ({ isModalOpen, setIsModalOpen, setRender, render }) => {
  const [file, setFile] = useState(null);

  const handlerCreateOrder = () => {
    // console.log('crear order sobrepeso file:', file[0]);
    const formData = new FormData();
    formData.append('excel_file', file[0]);
    formData.append('email', 'vivianakgp@gmail.com');
    createOverweightOrder(formData)
      .then((response) => {
        // console.log('create order overweight res:', response);
        if (response.success === true) {
          setIsModalOpen(false);
          setFile(null);
          setRender(!render);
          const MSG = `${response.message}. Puedes revisa el correo ${response.to_email} para consultar los resultados.`;
          toast.success(MSG, {
            position: 'top-right',
            autoClose: 7000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else {
          setIsModalOpen(false);
          setFile(null);
          toast.error('Error', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Crear orden de sobrepesos</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Application theme={theme}></Application> */}
        <FileSelector
          className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto mx-auto"
          // style={containerStyles}
          label="Subir archivo excel"
          placeholder="Sube o arrastra tu archivo aquí"
          bottomHelpText="Selecciona solo un archivo"
          onChange={setFile}
          borderRadius="semi-rounded"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={() => setIsModalOpen(false)}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handlerCreateOrder}>
          Continuar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default OrderModal;
