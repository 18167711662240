import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Button, Col, Row } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const Surcharges = () => {
  const title = 'Cargos Adicionales';
  const description = 'Cargos Adicionales Plataforma Autopaquete';

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="">
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Inicio</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End  */}
      <Card className="mb-5">
        <Card.Body>
          <Row>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 pb-2 text-start responsive-title text-wrap">
                        Vigencia de guías
                      </Button>
                      <div className="responsive-text mb-2 ">A partir de la fecha de creación (Días naturales).</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex: 5 días</li>
                        <li>Estafeta: 6 días</li>
                        <li>J&T Express: 3 días</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 pb-2 text-start responsive-title text-wrap">
                        Cancelación de guías
                      </Button>
                      <div className="responsive-text mb-2 ">
                        En caso de que el cliente CANCELE la guía y posteriormente la utilice, se le cobrará la guía en precio unitario y como penalización un
                        cobro adicional.
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li> PaquetExpress: $199</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 pb-2 text-start responsive-title text-wrap">
                        Cancelación de guías sin usar
                      </Button>
                      <div className="responsive-text mb-2 ">Cuando la guía se crea, pero no se utiliza y se manda a reembolso.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li> DHL: $25</li>
                        <li> Otras paqueterías: Sin costo</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Recolecciones
                      </Button>
                      <div className="responsive-text">Recolección en día inhábil.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>FedEx: $248</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Cargo por Combustible
                      </Button>
                      <div className="responsive-text">Entrega genero un gasto extra para la paquetería, por la zona.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Variable</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Zona extendida
                      </Button>
                      <div className="responsive-text">Recolección o entrega en lugares de difícil acceso o complejidad.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex: $170.00</li>
                        <li>Estafeta: $170.00</li>
                        <li>Paquetexpress $295</li>
                        <li>DHL $272</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>

            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Dimensiones
                      </Button>
                      <div className="responsive-text">Empaque que excedan el máximo de medidas en su lado más largo.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex Express (+120 cm): $320.00</li>
                        <li>Fedex Economy(+120 cm): $146.50</li>
                        <li>Fedex Economy(+157 cm): $655.00</li>
                        <li>Fedex Economy(+330 cm): $655.00</li>
                        <li>Estafeta Terrestre (+150 x 115 x 115 cm): $260.00</li>
                        <li>J&T Express (+60 x 60 x 40 cm): No existe servicio para dimensiones mayores.</li>
                        <li>DHL (+120 CM): $442.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>





            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Visita o servicio adicional
                      </Button>
                      <div className="responsive-text">
                        Se realizan más de dos intentos de entrega o cuando el operador realiza una gestión para obtener la dirección correcta.
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>RedPack: $45.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Devoluciones
                      </Button>
                      <div className="responsive-text">
                        Devolución o retorno por razones que no están bajo el control de la paquetería, o a solicitud del usuario.
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>General: Costo de gestión del retorno</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Corrección o cambio de domicilio
                      </Button>
                      <div className="responsive-text">
                        Cuando la dirección del destinatario está incompleta o es incorrecta y si el teléfono no es válido. Solo se aplicará en caso de que
                        proceda el reporte. (Siempre y cuando se nos notifique antes de encontrarse en ruta de entrega).
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>
                          FedEx y Estafeta: No es posible realizar cambio
                          <em> *Consulta sección de “Soporte” para ver opciones*</em>
                        </li>
                        <li>FedEx: Corrección de numero de casa $85.50</li>
                        <li>DHL cambio domicilio $90</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Póliza de seguro
                      </Button>
                      <div className="responsive-text">
                        El seguro cubre el valor declarado del contenido previo pago del deducible (por encima de la responsabilidad estándar). Cubriendo la
                        cantidad necesaria para reparar o reemplazar un envío en caso de pérdida a daño. Te recomendamos adquirir seguro en contenidos
                        superiores a los $2,500.00 m.x.
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Protección de su envío con tan sólo el 2% del valor declarado.</li>
                        <li>20% de deducible.</li>
                        <li>Cobertura hasta por $ 60,000.00 por guía.</li>
                        <li>Póliza de Seguro por $ 49.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Peso
                      </Button>
                      <div className="responsive-text"> Empaque que excedan el máximo de peso (físico o volumétrico):</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>
                          Fedex Economy(+30 kg): $137.00
                          <em> *Para paquetes mayores a 30 kg, utiliza la opción FedEx Economy C (max 68kg)*</em>
                        </li>
                        <li>FedEx Economy(+68 kg): $655.00 + $35 por kilogramo a partir de los 69 kg</li>
                        <li>
                          Estafeta (+68 kg): <em>No hay servicio</em>
                        </li>
                        <li>J&T Express: 28kg máximo (Para BC Sur y Norte, no puede exceder de 10kg, ni de los 60cm por lado)</li>
                        <li>DHL (+70 kg): $442.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Manejo especial
                      </Button>
                      <div className="responsive-text">Cuando se requiere de algún manejo especial por parte de la paquetería.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-4 responsive-text">
                        <li>
                          FedEx: $137.00
                          <ul className="ps-1 ps-sm-5">
                            <li>La mercancía sobresalga del empaque principal.</li>
                            <li>
                              El empaque principal no está hecho de cartón corrugado, incluyendo, entre otros, metal, madera, lona, cuero, unicel ( ej.,
                              hieleras), plástico duro o blando, playo, costal, poliestireno expandido (ej., espuma de poliestireno).
                            </li>
                            <li>Empaque cubierto con playo o plástico.</li>
                            <li>Tenga forma redonda, cilíndrica, incluyendo (sin limitarse), tubos, latas, cubetas, barriles, llantas.</li>
                            <li>
                              Esté atado con fleje plástico, fleje metálico, soga, cuerda, cinta de papel, tela o tenga ruedas (ej., bicicletas) manijas o
                              correas, incluídos empaques con playo sin ajustar al empaque, con riesgo de romperse.
                            </li>
                            <li>Tenga más de dos paquetes unidos con diferentes dimensiones, peso máximo 34 kg.</li>
                            <li>Las cajas están dañadas, aplastadas, rotas o mojadas.</li>
                            <li> Pudiera enredarse o causar daños a otros paquetes o al sistema de clasificación FedEx.</li>
                          </ul>
                        </li>
                        <li>
                          Estafeta: $174.00
                          <ul className="ps-1 ps-sm-5">
                            <li>Envíos que no pueden ser operados en el sistema de bandas transportadoras.</li>
                            <li>Objetos que requieren de un cuidado especial, etiqueta de Frágil.</li>
                            <li>Empaque irregular como tubos, latas, cubetas, etc.</li>
                            <li>Alto, largo o ancho mayor a 100 centímetros.</li>
                            <li>Empaque de metal, madera o con partes de estos.</li>
                            <li>En su exterior tiene ruedas, manijas, correas, etc.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Surcharges;
