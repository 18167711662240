import React, { useState, useEffect, useRef } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Dropdown, Pagination } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../../../../../../components/loading/Loading';
import LoadingPulse from '../../../../../../components/loading/LoadingPulse';
import Paginate from '../../../../../components/Paginate';
import StatementsCSV from '../../../../../../components/reactCsv/StatementsCSV';
import StatementRow from './components/StatementRow';
import StatementCard from './components/StatementCard';
// api
import { getStatements } from '../../../../../api/Api-admin';

const ShipmentHistory = () => {
  const title = 'Historial Movimientos';
  const description = 'Historial Movimientos Plataforma Autopaquete';
  const { crmUserId, userName } = useParams();
  const history = useHistory();

  // -Local States
  const [statementHistory, setStatementHistory] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  // - Use references to do scroll Up
  const startRef = useRef(null);

  const settingPage = (record) => {
    setPageSize(record);
    setCurrentPage(1);
  };
  // - Get all statement
  const handlerGetStatementsHistory = (page, items) => {
    // getStatements = (crmId, type, page, limit)
    setIsLoading(true);
    getStatements(crmUserId, '', page, items)
      .then((response) => {
        // console.log('historial statements user:', response);
        if (response.statement !== undefined) {
          setStatementHistory(response.statement);
          setTotalPages(response.total_pages);
          startRef.current.scrollIntoView({ behavior: 'smooth' });
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error('Error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      });
  };
  useEffect(() => {
    handlerGetStatementsHistory(currentPage, pageSize);
  }, [pageSize, currentPage]);
  return (
    <>
      {statementHistory === null && <Loading />}
      {isLoading && <LoadingPulse />}
      {/* pageContainer I use it to add opacity screen while loading data */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        {/* *** Title Start ***  */}
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
              <div className="muted-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer" onClick={() => history.goBack()}>
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </div>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
          </Row>
        </div>
        {/* *** Title End *** */}

        {/* *** Dropdown and Excel Buttons Start *** */}
        {/* text-center text-sm-start */}
        <h2 className="cta-1 text-primary mb-5 ">{userName}</h2>
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row className="g-0 d-flex justify-content-end aling-items-center">
            <Col xs="auto">{totalPages > 1 && <StatementsCSV statements={statementHistory} crmId={crmUserId} />}</Col>
            <Col xs="auto" className=" p-0 ms-2">
              <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${statementHistory?.length === 0}`}>
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                  {`${pageSize} Movimientos`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Movimientos
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* Length End */}
            </Col>
          </Row>
          <Row className="g-0 mb-3">
            <div className={`text-muted  mt-3 ${statementHistory?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div>
          </Row>
          {/* *** Dropdown and Excel Buttons End *** */}
          {/* *** Main Page Start *** */}

          {/* List Header Start */}
          <StatementRow />
          {/* List Header End */}
          {/* List shipment Items End */}
          {statementHistory?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center mt-5">Error</h3>
              <img alt="box" src="/img/animation/emptyBox.webp" style={{ width: '50px', height: '50px' }} />
            </div>
          ) : (
            <>
              {/* List shipment Items Start */}
              {statementHistory?.map((shipping, index) => (
                <StatementCard key={index} data={shipping} />
              ))}
              {/* List shipment Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages === 0 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
        {/* *** Main Page Start *** */}
      </div>
    </>
  );
};

export default ShipmentHistory;
