import React from 'react';
import { NavLink } from 'react-router-dom';

import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
// external libraries
import moment from 'moment';

const CardShipping = ({ pickup }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col xs="auto" className="positio-relative">
          <NavLink to="/envios/historial/detalle">
            <img src={pickup.service_img} alt="product" className="card-img card-img-horizontal sw-9 sw-sm-11 h-100" />
          </NavLink>
        </Col>
        <Col className="py-4 py-lg-0 ps-3 ps-sm-5 pe-2 pe-3 pe-sm-5 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" sm="9" lg="3" className="d-flex flex-column mb-lg-0 mb-2 pe-3 d-flex order-1 h-lg-100 justify-content-center">
              <div className="text-muted text-small d-lg-none mb-1">Servicio / ID Recolección</div>
              <div className="p-0 pe-2 text-primary d-flex align-items-center">{pickup.shipping_code}</div>
              <div className="text-medium text-muted text-truncate">{`ID: ${pickup.pickup_id}`}</div>
            </Col>
            <Col
              xs="12"
              sm="3"
              lg="3"
              className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center  align-items-center align-items-sm-end align-items-lg-start order-2"
            >
              <div className="text-muted text-small d-lg-none">Fecha</div>
              <div className="lh-1 text-alternate">{moment(pickup.pickup_date).format('DD-MM-YYYY')}</div>
            </Col>
            <Col lg="4" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
              <div className="text-muted text-small d-lg-none">ID Envío</div>
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
                <NavLink to={`/envios/historial/detalle/${pickup.guide}`}>
                  <div className="lh-1">{pickup.guide}</div>
                </NavLink>
              </OverlayTrigger>
            </Col>
            <Col sm="8" className="d-none d-sm-flex d-lg-none order-4" />
            <Col xs="8" sm="2" lg="1" className="d-flex flex-column mb-2 mb-lg-0 align-items-end align-items-lg-center order-4 justify-content-center">
              <div className="text-muted text-small d-lg-none">Paquetes</div>
              <div className="text-alternate">{`${pickup.quantity}`}</div>
            </Col>
            <Col xs="4" sm="2" lg="1" className="d-flex flex-column mb-2 mb-lg-0 pe-lg-2 align-items-end order-last justify-content-center">
              <div className="text-muted text-small d-lg-none">Peso total</div>
              <div className="text-alternate">
                {pickup.weightTotal}
                <span className="text-small text-muted"> kg</span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default CardShipping;
