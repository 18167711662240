import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Application, DatePicker } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import moment from 'moment';
import { setLabeIdForPickup } from 'slices/shipping/shippingDataSlice';
// api
import { createPickup, getZipCode, getShippingDetails } from '../../../../api/Api-user';
import Loading from '../../../../components/loading/Loading';

const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};

const NewPickup = () => {
  const title = 'Nueva recolección';
  const description = 'Recolección Plataforma Autopaquete';
  // - Global State from Store.js form Values
  const { labelIdForPickup } = useSelector((state) => state.shipping);
  const dispatch = useDispatch();

  // - Use references to do scroll on this page
  // const sideStreets = useRef(null);
  // creationDate: Mon Apr 22 2024 18:33:49 GMT-0600 (hora estándar central)
  let date = new Date();

  const month = date.getMonth().toString();
  const year = date.getFullYear();
  // date = new Date(year, month, 27); // to make test

  const dayOfWeek = date.getDay(); // return a number dayOfWeek = 6;
  const currentTime = date.getHours(); // return a number hour in 24hrs
  // currentTime = 10; // return a number hour in 24hrs

  let today = date.getDate(); // return a number
  let nextDay = date.getDate() + 1;

  // - Start Disabled days of week
  if (dayOfWeek === 5) {
    nextDay = today;
    date = new Date(year, month, today);

    if (currentTime >= 14) {
      today += 3;
      nextDay += 4;
      date = new Date(year, month, today);
    }
  }
  if (dayOfWeek === 6) {
    today += 2;
    nextDay += 2;
    date = new Date(year, month, today);
  }
  if (dayOfWeek === 0) {
    today += 1;
    nextDay += 1;
    date = new Date(year, month, today);
  }
  // - End tart Disabled days of week
  // - Start test lunes, martes, miercoles, jueves
  if (currentTime >= 14) {
    if (dayOfWeek === 1 || dayOfWeek === 2 || dayOfWeek === 3) {
      today += 1;
      nextDay += 1;
      date = new Date(year, month, today);
    } else if (dayOfWeek === 4) {
      today += 1;
      nextDay = today;
      date = new Date(year, month, today);
    }
  }
  // - Set hour pickup schedule from 9hrs to 19hrs

  const minDate = new Date(year, month, today);
  const maxDate = new Date(year, month, nextDay);
  // - Local State
  const [isLoading, setIsLoading] = useState(false);
  const [eveningSchedule, setEveningSchedule] = useState(false);
  const [morningSchedule, setMorningSchedule] = useState(false);

  const [selectDate, setSelectDate] = useState({ date });
  const [pickup, setPickup] = useState({
    shipping_code: '',
    company: '',
    name: '',
    phone: '',
    street: '',
    street_number: '',
    email: '',
    neighborhood: '',
    zip_code: '',
    reference: '',
    state: '',
    rfc: 'XAXX010101000',
    city: '',
    shipping_id: '',
    total_packages: '',
    total_weight: '',
    pickup_time: '',
    pickup_date: moment(selectDate.date).format().slice(0, 10),
    side_streets: '',
  });
  const [pickupErr, setPickupErr] = useState({
    shipping_code: '',
    company: '',
    name: '',
    phone: '',
    street: '',
    street_number: '',
    email: '',
    neighborhood: '',
    zip_code: '',
    reference: '',
    state: '',
    rfc: '',
    city: '',
    shipping_id: '',
    total_packages: '',
    total_weight: '',
    pickup_time: '',
    pickup_date: '',
    side_streets: '',
  });

  // - Reset form
  const cleanForm = () => {
    setPickup({
      ...pickup,
      shipping_code: '',
      company: '',
      name: '',
      phone: '',
      street: '',
      street_number: '',
      email: '',
      neighborhood: '',
      zip_code: '',
      reference: '',
      state: '',
      rfc: '',
      city: '',
      // shipping_id: '',
      // total_packages: '',
      // total_weight: '',
      pickup_time: '',
      // pickup_date: moment(selectDate.date).format().slice(0, 10),
      // side_streets: '',
    });
  };
  // - Data Validation and Updating States with Reducers
  const updateField = (data, validation, field) => {
    if (data === '' || validation.test(data)) setPickup({ ...pickup, [field]: data });
  };
  // - Form autofill
  const getShippingData = (labelId) => {
    getShippingDetails(labelId)
      .then((response) => {
        // console.log('INFO DE GUIA DESDE FIREBASE', response);
        if (response.shipping_code !== undefined) {
          // clean up previous errors Start
          setPickupErr({
            ...pickupErr,
            company: '',
            name: '',
            phone: '',
            street: '',
            street_number: '',
            email: '',
            neighborhood: '',
            zip_code: '',
            reference: '',
            state: '',
            rfc: '',
            city: '',
            shipping_id: '',
            // total_packages: '',
            // total_weight: '',
            pickup_time: '',
            // pickup_date: '',
            // side_streets: '',
          });
          // clean up previous errors End
          // Set states with data from firebase Start
          setPickup({
            shipping_code: response.shipping_code.replace(/_/g, ' '),
            company: response.address_from.company,
            name: response.address_from.name,
            phone: response.address_from.phone,
            street: response.address_from.street,
            street_number: response.address_from.street_number,
            email: response.address_from.email,
            neighborhood: response.address_from.neighborhood,
            zip_code: response.address_from.zip_code,
            reference: response.address_from.reference,
            state: response.address_from.state,
            rfc: response.address_from.rfc,
            city: response.address_from.city,
            shipping_id: labelId,
            total_packages: '',
            total_weight: '',
            pickup_time: '',
            pickup_date: moment(selectDate.date).format().slice(0, 10),
            side_streets: '',
          });
          // Set states with data from firebase End
          // Clean up pickup_time status
          setMorningSchedule(false);
          setEveningSchedule(false);
        }
        // else {
        //   cleanForm();
        // }
      })
      .catch(() => {
        // console.log('Err:', err)
      });
  };
  // useEffect autofill when user come from documentacion page
  useEffect(() => {
    if (labelIdForPickup) {
      getShippingData(labelIdForPickup);
      setPickup({ ...pickup, shipping_id: labelIdForPickup });
    }
    return () => {
      // Código a ejecutar al salir de la página
      // console.log('2 Saliendo de la página pickups...');
      dispatch(setLabeIdForPickup(''));
    };
  }, []);
  useEffect(() => {
    if (pickup.shipping_id.length === 0) {
      cleanForm();
    }
  }, [pickup.shipping_id]);

  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    if (pickup.zip_code.length === 5) {
      getZipCode(pickup.zip_code).then((res) => {
        // console.log('response:', res);
        if (res.length > 0) {
          setPickup({ ...pickup, city: res[0].city, state: res[0].state.name });
          setPickupErr({ ...pickupErr, city: '', state: '' });
        } else {
          setPickupErr({ ...pickupErr, zip_code: 'Código postal invalido' });
        }
      });
    } else {
      setPickupErr({ ...pickupErr, zip_code: '' });
      setPickup({ ...pickup, city: '', state: '' });
    }
  }, [pickup.zip_code]);
  // Schema Validation
  const validationSchema = Yup.object().shape({
    shipping_id: Yup.string().required('Campo requerido'),
    name: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    zip_code: Yup.string().min(5, 'CP debe tener 5 caracteres').required('Campo requerido'),
    street: Yup.string().required('Campo requerido'),
    street_number: Yup.string().required('Campo requerido'),
    neighborhood: Yup.string().required('Campo requerido'),
    reference: Yup.string().required('Campo requerido'),
    phone: Yup.string().min(10, 'Este campo debe tener 10 caracteres').required('Campo requerido'),
    total_packages: Yup.string().required('Campo requerido'),
    total_weight: Yup.string().required('Campo requerido'),
    side_streets: Yup.string().required('Campo requerido'),
    pickup_time: Yup.string().required('Selecciona un horario'),
  });
  // - Request handler
  const handlerCreatePickup = () => {
    setIsLoading(true);
    createPickup(pickup).then((response) => {
      // console.log('respuesta de la peticion:', response);
      // response.message.error.pickups[0]
      // console.log('respuesta de la peticion 111:', response.message.error.pickups[0]);

      setIsLoading(false);
      if (response.pickup_info) {
        // agregar validacion cuando sea null en el caso de dhl eco c

        if (response.pickup_info.pickup_id !== null) {
          toast.success(
            `Tu recolección ha sido programada exitosamente para el dia ${response.pickup_info.pickup_date}. Recolección Id: ${response.pickup_info.pickup_id}`,
            {
              position: 'top-right',
              autoClose: 8000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            }
          );
          setPickup({ ...pickup, shipping_id: '' });
          cleanForm();
        } else {
          toast.error('Error (null err) ', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      } else if (response.message) {
        // console.log('l:',response.message.error.pickups[0]);
        let msgErr;
        response.message.error.pickups.forEach((msg) => {
          msgErr = `Error: ${msg} Código: ${response.message.error_code}`;
        });
        toast.error(msgErr, {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else if (response.data) {
        // ${response.data[0].message}
        toast.error(`Error: ${response.data[0].message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });

    // .catch((err) => console.log('Error catch create pickup:', err));
    // else {
    //   toast.error('Lo siento, no se pudo programar la recolección.', {
    //     position: 'top-right',
    //     autoClose: 5000,
    //     hideProgressBar: true,
    //     closeOnClick: false,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: 'dark',
    //   });
    // }
  };
  // Validation
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(pickup, { abortEarly: false });
      // console.log('crear recoleccion de:', pickup);
      handlerCreatePickup();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      // console.log('create pickup err:', formErrors);
      // revisar linea de abajo la idea es que vaya al primer erro que encuentre
      // sideStreets.current.scrollIntoView({ behavior: 'smooth' });
      setPickupErr(formErrors);
      toast.error('Por favor, completa todos los campos.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  // console.log('errores pick***', pickupErr);
  // console.log(selectDate.date);
  // console.log('Datos para recoleccion:', pickup);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <Col xs="12" lg="10">
          <Row className="d-flex flex-column flex-sm-row mb-5">
            {/* Title Start */}
            <Col>
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/envios/recolectar">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </Col>
      </div>
      {/* Title End */}
      <Form onSubmit={validateForm}>
        {isLoading && <Loading />}
        {/* Top Buttons Start */}
        <Col xs="12" lg="10" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0">
          <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
            <CsLineIcons icon="save" /> <span>Programar Recolección</span>
          </Button>
        </Col>
        {/* Top Buttons End */}
        <Col xs="12" lg="10" className="col-lg mt-5">
          {/* Start */}
          <Card className="mb-5">
            <Card.Body>
              <h2 className="small-title">Datos de la guía</h2>
              <Row className="g-3 mb-5">
                <Col md="6" className="position-relative">
                  <Form.Label>ID Envío</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="paga el Id de tu envío"
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, shipping_id: '' });
                      getShippingData(e.target.value);
                      updateField(e.target.value, /^[0-9]+$/i, 'shipping_id');
                    }}
                    value={pickup.shipping_id}
                  />
                  {pickupErr.shipping_id && <div className={`invalid-tooltip ${pickupErr.shipping_id ? 'd-block' : 'd-none'}`}>{pickupErr.shipping_id}</div>}
                </Col>
                <Col md="6">
                  <Form.Label>Paqueteria</Form.Label>
                  <div className="small-title">{pickup.shipping_code}</div>
                </Col>
              </Row>
              <h2 className="small-title">Dirección de recolección</h2>
              <Row className="g-3 mb-5">
                <Col sm="9" className="position-relative">
                  <Form.Label>Nombre del contacto</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={29}
                    // placeholder="máximo 29 caracteres"
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, name: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'name');
                    }}
                    value={pickup.name}
                  />
                  {pickupErr.name && <div className={`invalid-tooltip ${pickupErr.name ? 'd-block' : 'd-none'}`}>{pickupErr.name}</div>}
                </Col>
                <Col sm="3" className="position-relative">
                  <Form.Label>C.P</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={5}
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, zip_code: '' });
                      updateField(e.target.value, /^[0-9]+$/i, 'zip_code');
                    }}
                    value={pickup.zip_code}
                  />
                  {pickupErr.zip_code && <div className={`invalid-tooltip ${pickupErr.zip_code ? 'd-block' : 'd-none'}`}>{pickupErr.zip_code}</div>}
                </Col>
                <Col sm="9" className="position-relative">
                  <Form.Label>Nombre de la calle</Form.Label>
                  {/* <div style={{ position: 'relative', zIndex: 1 }}></div> */}
                  <Form.Control
                    type="text"
                    maxLength={50}
                    // placeholder="máximo 50 caracteres"
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, street: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'street');
                    }}
                    value={pickup.street}
                  />
                  {pickupErr.street && <div className={`invalid-tooltip ${pickupErr.street ? 'd-block' : 'd-none'}`}>{pickupErr.street}</div>}
                </Col>
                <Col sm="3" className="position-relative">
                  <Form.Label>Número</Form.Label>
                  {/* <div style={{ position: 'relative', zIndex: 1 }}> </div> */}
                  <Form.Control
                    type="text"
                    maxLength={10}
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, street_number: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'street_number');
                    }}
                    value={pickup.street_number}
                  />
                  {pickupErr.street_number && (
                    <div className={`invalid-tooltip ${pickupErr.street_number ? 'd-block' : 'd-none'}`}>{pickupErr.street_number}</div>
                  )}
                </Col>
                <Col md="6" className="position-relative">
                  <Form.Label>Colonia</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={35}
                    // placeholder="máximo 35 caracteres"
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, neighborhood: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'neighborhood');
                    }}
                    value={pickup.neighborhood}
                  />

                  {pickupErr.neighborhood && <div className={`invalid-tooltip ${pickupErr.neighborhood ? 'd-block' : 'd-none'}`}>{pickupErr.neighborhood}</div>}
                </Col>
                <Col xs="12" md="6" className="d-flex justify-content-start justify-content-sm-end overflow-x-hidden">
                  <Col xs="auto" className="me-5">
                    <Form.Label>Ciudad</Form.Label>
                    <div className="small-title">{pickup.city}</div>
                  </Col>
                  <Col xs="auto" className="me-5">
                    <Form.Label>Estado</Form.Label>
                    <div className="small-title">{pickup.state}</div>
                  </Col>
                </Col>
                <Col sm="8" className="position-relative">
                  <Form.Label>Referencias del lugar</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={30}
                    // placeholder="máximo 20 caracteres"
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, reference: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'reference');
                    }}
                    value={pickup.reference}
                  />
                  {pickupErr.reference && <div className={`invalid-tooltip ${pickupErr.reference ? 'd-block' : 'd-none'}`}>{pickupErr.reference}</div>}
                </Col>
                <Col className="position-relative">
                  <Form.Label>Telefono</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={10}
                    // placeholder="10 dígitos"
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, phone: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'phone');
                    }}
                    value={pickup.phone}
                  />
                  {pickupErr.phone && <div className={`invalid-tooltip ${pickupErr.phone ? 'd-block' : 'd-none'}`}>{pickupErr.phone}</div>}
                </Col>
                <Col sm="8" className="position-relative">
                  <Form.Label>Entre calles </Form.Label>
                  <Form.Control
                    type="text"
                    // maxLength={}
                    // placeholder=""
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, side_streets: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'side_streets');
                    }}
                    value={pickup.side_streets}
                  />
                  {pickupErr.side_streets && <div className={`invalid-tooltip ${pickupErr.side_streets ? 'd-block' : 'd-none'}`}>{pickupErr.side_streets}</div>}
                </Col>
              </Row>
              <h2 className="small-title">Información del paquete</h2>
              <Row className="g-3 mb-5">
                <Col xs="6" md="3" className="position-relative">
                  <Form.Label>Cantidad total</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={2}
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, total_packages: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'total_packages');
                    }}
                    value={pickup.total_packages}
                  />
                  {pickupErr.total_packages && (
                    <div className={`invalid-tooltip ${pickupErr.total_packages ? 'd-block' : 'd-none'}`}>{pickupErr.total_packages}</div>
                  )}
                </Col>
                <Col xs="6" md="3" className="position-relative">
                  <Form.Label>Peso total</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={4}
                    onChange={(e) => {
                      setPickupErr({ ...pickupErr, total_weight: '' });
                      updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'total_weight');
                    }}
                    value={pickup.total_weight}
                  />
                  {pickupErr.total_weight && <div className={`invalid-tooltip ${pickupErr.total_weight ? 'd-block' : 'd-none'}`}>{pickupErr.total_weight}</div>}
                </Col>
                <Col xs="12" sm="6">
                  <Application theme={theme}>
                    <DatePicker
                      label="Fecha de recolección"
                      className="datepicker rainbow-p-around_medium p-0 pt-1"
                      borderRadius="semi-rounded"
                      value={selectDate.date}
                      minDate={minDate}
                      maxDate={maxDate}
                      onChange={(value) => {
                        setSelectDate({ ...selectDate, date: value });
                        setPickup({ ...pickup, pickup_date: moment(value).format().slice(0, 10) });
                      }}
                    />
                  </Application>
                </Col>
                <Col xs="12" sm="6">
                  <Form.Label>Horario</Form.Label>
                  <div style={{ position: 'relative', zIndex: 1, maxWidth: '127px' }}>
                    <div className="ms-2">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={eveningSchedule}
                        onClick={() => {
                          setEveningSchedule(!eveningSchedule);
                          setPickup({ ...pickup, pickup_time: 'vespertino' });
                          setPickupErr({ ...pickupErr, pickup_time: '' });
                          setMorningSchedule(false);
                        }}
                      />
                      <label className="form-label">Vespertino</label>
                    </div>
                    <div className="ms-2">
                      <input
                        type="checkbox"
                        className="form-check-input me-2"
                        checked={morningSchedule}
                        onClick={() => {
                          setMorningSchedule(!morningSchedule);
                          setPickup({ ...pickup, pickup_time: 'matutino' });
                          setPickupErr({ ...pickupErr, pickup_time: '' });
                          setEveningSchedule(false);
                        }}
                      />
                      <label className="form-label">Matutino</label>
                    </div>
                    {pickupErr.pickup_time && <div className={`invalid-tooltip ${pickupErr.pickup_time ? 'd-block' : 'd-none'}`}>{pickupErr.pickup_time}</div>}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {/* End */}
        </Col>
      </Form>
    </>
  );
};

export default NewPickup;
