import React from 'react';
import { NavLink } from 'react-router-dom';
import Rating from 'react-rating';
import { Row, Col, Button, Card } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// import 'react-image-lightbox/style.css';

const Packaging = () => {
  const title = 'Embalajes';
  const description = 'Embalajes Plataforma Autopaquete';

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Inicio</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}
        </Row>
      </div>

      {/* Product Start */}
      <Card className="mb-5">
        <Card.Body>
          <Row className="g-5">
            <Col xl="6" className="position-relative">
              <img alt="detail" src="/img/embalajes/banner.webp" className="responsive border-0 rounded-md img-fluid mb-3 w-100 sh-35 sh-md-45 sh-xl-60" />
            </Col>
            <Col xl="4" className="pt-0 pt-xl-5 sh-xl-60 d-flex flex-column justify-content-between">
              <div>
                <h1 className="embalaje-title">Tips</h1>
                <h3 className="cta-1">Para enviar de manera segura tus productos</h3>
                <Rating
                  className="me-2"
                  initialRating={5}
                  readonly
                  emptySymbol={<i className="cs-star text-primary" />}
                  fullSymbol={<i className="cs-star-full text-primary" />}
                />
                <div className="text-semi-large mt-5">
                  El embalaje se refiere al proceso de empaquetar o empacar productos para su protección. Es necesario y muy importante que tus productos
                  cuenten con un buen embalaje para evitar daños durante su manipulación y transporte hacia su destino.
                </div>
                <div>{/* <div className="text-muted d-inline-block text-small align-text-top">(25 Reviews)</div> */}</div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/* Product End */}

      {/* Basic tips Start */}
      <div className="d-flex flex-column">
        <h2 className="h3 small-title">¿Cómo preparar mi envío?</h2>
        <p className="cta-3">Te compartimos los básicos para enviar de manera correcta</p>
      </div>
      <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2 mb-5 mt-3 ">
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            {/* <Badge bg="primary" className="me-1 position-absolute e-3 t-n2 z-index-1">
              SALE
            </Badge> */}
            <Card.Img src="/img/embalajes/medidas.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <h4 className="h4 mb-0 body-link stretched-link">Medidas del paquete</h4>
              <div className="mt-4 mb-2">
                Mide con flexómetro (cm) cada uno de los lados e ingresa los datos correctos. Y utiliza una bascula para calcular el peso físico real.
              </div>
              <div>Te recomendamos colocar la medida más grande para el largo del paquete.</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/volumetrico.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <h4 className="h4 mb-0 body-link stretched-link">Peso final</h4>
              <div className="mt-4 mb-2">
                Para determinar el peso final de tu caja, se tomará lo que resulte mayor entre el peso físico y peso volumétrico. La fórmula para conocer el
                peso volumétrico de tu caja, se obtiene multiplicando los centímetros de:
                <div className="mt-2">Largo x alto x ancho, dividiendo el resultado entre 5,000</div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/embalajeExterno.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <h4 className="h4 mb-0 body-link stretched-link">Embalaje externo</h4>
              <div className="mt-4 mb-2">
                La caja o sobre debe ajustarse adecuadamente a tu producto. Debe soportar el peso del contenido y dejar espacio para un buen embalaje interno.
                <br />
                Puedes incluir por fuera, tu logotipo, etiqueta de marca, medidas o datos adicionales de la dirección de destino.
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/embalajeInterno.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <h4 className="h4 mb-0 body-link stretched-link">Embalaje interno</h4>
              <div className="mt-4 mb-2">
                Es el material de protección para mantener a salvo el contenido. Éste varía dependiendo del tipo de producto. Puedes utilizar: burbuja,
                cacahuate, esquineros y separadores de cartón o unicel, espuma de polietileno o contenedores a la medida.
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Basic tips End */}

      {/* avoid surcharges Start */}
      <div className="d-flex flex-column">
        <h2 className="h3 small-title">Evita cargos adicionales</h2>
        <p className="cta-3">Cuida que tu paquete no presente alguna de las siguientes situaciones</p>
      </div>
      <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-2 mb-5 mt-3">
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/cajaAbierta.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">El contenido y protección interna sobresale del empaque.</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/cajaPlastico.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">
                El empaque principal es de cartón corrugado, incluyendo, entre otros, metal, madera, lona, cuero, unicel ( ej., hieleras), plástico duro o
                blando, playo, costal, poliestireno expandido (ej., espuma de poliestireno).
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/cajaConPlastico.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">Caja cubierta con playo o plástico sin ajustar, de manera que el paquete pueda atorarse en las bandas.</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/formaCilindrica.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">Embalaje externo con forma cilíndrica, incluyendo, entre otros, tubos, latas, cubetas, barriles, llantas.</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/cajasUnidas.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">Paquetes unidos (con dimensiones iguales o diferentes).</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/paqueteAtados.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">
                Atados con fleje plástico, fleje metálico, soga, cuerda, cinta de papel o masking, tela o tenga ruedas (ej., bicicletas)
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/maletas.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">Contiene ruedas, manijas o correas (maletas) o pueda enredarse y causar daños a otros paquetes.</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/paqueteRoto.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">La caja esta dañadas, aplastadas, rotas, mojadas o requieran de embalaje por parte de la paquetería.</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/fragil.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">Etiqueta de frágil, necesita ser trasladados con mayor precaución o requiere manejo especial.</div>
              <a className="pe-3" href="/ayuda/recargos">
                Revisar cargos adicionales de Estafeta
              </a>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="5" xl="3" className="mb-3">
          <Card className="h-100">
            <Card.Img src="/img/embalajes/pesoExedido.webp" className="card-img-top sh-22" alt="card image" />
            <Card.Body>
              <div className="mt-4 mb-2">Peso y dimensiones excedidos.</div>
              <a className="pe-3" href="/ayuda/recargos">
                Ver dimensiones máximas por paquetería
              </a>
            </Card.Body>
          </Card>
        </Col>

        {/* sm="8" lg="5" xl="4" mt-xl-3 mt-xxl-0 */}

        <Col xs="12" sm="7" lg="5" xl="6" xxl="4" className="d-flex align-items-center align-self-sm-start ">
          <Card className="w-100 h-100 hover-img-scale-up ">
            <img src="/img/embalajes/imgBtn.webp" className="card-img h-100 scale" alt="card image" />
            <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
              <div className="d-flex flex-column h-100 justify-content-between align-items-start">
                <div className="cta-2 text-black w-80">Conoce más acerca de los cargos adicionales de las paqueterías</div>
                <NavLink to="/ayuda/recargos" className=" mt-3 stretched-link">
                  <Button variant="primary" className="btn-icon btn-icon-start mt-3 stretched-link">
                    <CsLineIcons icon="chevron-right" /> <span>Detalles</span>
                  </Button>
                  {/* <CsLineIcons icon="chevron-right" /> <span>Detalles</span> */}
                </NavLink>
                {/* <Button variant="primary" className="btn-icon btn-icon-start mt-3 stretched-link">
                  <CsLineIcons icon="chevron-right" /> <span>Detalles</span>
                </Button> */}
              </div>
            </div>
          </Card>
        </Col>
      </Row>
      {/* avoid surcharges End */}
    </>
  );
};

export default Packaging;
