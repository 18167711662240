import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Col, Row, Card, Button } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';

// API
import { createShipping } from '../../../../api/Api-user';
import { setShippingData } from '../../../../slices/shipping/shippingDataSlice';
// reducers
import { clearSenderForm } from '../../../../slices/shipping/senderDataSlice';
import { clearReceiverForm } from '../../../../slices/shipping/receiverDataSlice';
import { clearParcelForm } from '../../../../slices/shipping/parcelDataSlice';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const SupplierCard = ({ rate, shippingData, setIsLoading, page }) => {
  // const [isInstantPickup, setIsInstantPickup] = useState(false);
  const underscoresRegex = /_+/g;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const goShippingForm = () => {
    // - Clear Form
    dispatch(clearSenderForm());
    dispatch(clearReceiverForm());
    dispatch(clearParcelForm());
    history.push('/envios/enviar');
  };

  const handlerShipping = (shippingCode) => {
    setIsLoading(true);
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 3000);
    // console.log('DATOS PARA CREAR GUIA:', shippingCode, shippingData.addressFrom, shippingData.addressTo, shippingData.parcel);
    createShipping(shippingCode, shippingData.addressFrom, shippingData.addressTo, shippingData.parcel)
      .then((response) => {
        // console.log('response create shipping:', response);
        let msgErr = '';
        // return response.data; response.id !== undefined
        setIsLoading(false);
        if (response?.data.shipping_label_id !== undefined) {
          dispatch(setShippingData(response.data));
          history.push('/envios/enviar/documentacion');
        } else if (response?.data.error.shippings) {
          // para obtener este error no mandes el valor del producto y selecciona estafeta
          response.data.error.shippings.forEach((msg) => {
            msgErr = `Error: ${msg}. Código:  ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (response?.data.error.parcel) {
          // para tener este error no mandes un boleano en checkbox
          // Probable err: "No se puede realizar el envío con los datos ingresados.
          response.data.error.parcel.forEach((msg) => {
            msgErr = `Error: ${msg}. Código:  ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      })
      .catch(() => {
        // { config: , data:{error:[], error_code:""} , headers: , request: ,status: ,statusText: , etc..}
        setIsLoading(false);
        // console.log('-------- createShipping error ---------->', error);
        toast.error('Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });

        // if (dataError.parcel !== undefined) {
        //   const arrParcelErr = dataError.parcel.productValue;
        //    // const arrParcelErr = dataError.parcel.insurance;
        //    arrParcelErr?.forEach((err) => {
        //      msgError = `${err} Código: ${error.response.data.error_code}`;
        //    });
        //    toast.error(msgError, {
        //      position: 'top-right',
        //      autoClose: 5000,
        //      hideProgressBar: true,
        //      closeOnClick: false,
        //      pauseOnHover: true,
        //      draggable: true,
        //      progress: undefined,
        //      theme: 'dark',
        //    });
        //  }
      });
  };
  return (
    <Card className="mb-2 pe-2 pe-sm-4">
      {/* sh-22 sh-sm-18 sh-md-14  sw-sm-13 sw-md-15 className="g-0" ms-sm-2 my-sm-5 sw-sm-17 sw-7 height: '100%',  */}
      <Row className="d-flex justify-content-center ms-3">
        <Col xs="auto" className="h-sm-50" style={{ padding: '15px', paddingLeft: '0' }}>
          <img src={rate.service_img} className="rounded" alt="thumb" style={{ height: '85px', width: '100px' }} />
        </Col>
        <Col className="position-relative h-100">
          <Card.Body className="p-1 p-sm-1 p-xxl-1 mb-2">
            <Row className="h-100">
              {/* - Supplier Title */}
              <Col xs="12" xxl="4" className="mb-2 mb-md-0 d-flex p-3 pt-2 ">
                <div className="pt-0 pb-0 pe-2 w-100">
                  <div className="mb-0">{rate.shipping_code?.replace(underscoresRegex, ' ')}</div>
                  <div className="text-muted text-small">{rate.delivery_days}</div>
                  {rate.is_enabled_pickup && (
                    <div className="lh-sm">
                      Servicio de recolección
                      {/* {rate.is_instant_pickup && <span> con recolección al instante</span>} */}
                      <CsLineIcons icon="check-circle" size="14" className="mb-1 ms-1 text-success" />
                      {/* position-relative b-1 */}
                      <div className="text-medium">{rate.delivery_type === 'NORMAL' ? 'En domicilio' : 'En sucursal'}</div>
                    </div>
                  )}
                  {!rate.is_enabled_pickup && (
                    <div>
                      Servicio de recolección <CsLineIcons icon="close-circle" size="14" className="mb-1 text-danger" />
                    </div>
                  )}
                  {/* {rate.is_instant_pickup && (
                    <div>
                      <label className="form-label">¿Programar recolección?</label>
                      <input
                        type="checkbox"
                        className="form-check-input ms-2 mb-1"
                        checked={isInstantPickup}
                        onClick={() => {
                          setIsInstantPickup(!isInstantPickup);
                        }}
                      />
                    </div>
                  )} */}
                </div>
              </Col>
              <Col xs="12" xxl="8" className="pe-3  d-flex flex-column flex-xxl-row pe-xxl-0 pt-2">
                <div style={{ fontSize: '13px', width: '100%' }}>
                  <Row>
                    <Col>
                      <span>Tarifa Base</span>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-end">
                      <span className="fw-medium align-items-end">
                        <span className="text-small text-muted">$ </span>
                        {fmDecimal.from(rate.shipping_rates?.base)}
                      </span>
                    </Col>
                  </Row>
                  {rate.shipping_rates?.extra_weight > 0 && (
                    <Row>
                      <Col>
                        <span>Kg Adicionales</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.extra_weight)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {rate.shipping_rates?.excess_dimensions > 0 && (
                    <Row>
                      <Col>
                        <span>Exceso de Dimensiones</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.excess_dimensions)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {rate.shipping_rates?.zonification > 0 && (
                    <Row>
                      <Col>
                        <span>Zonificación</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.zonification)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {rate.shipping_rates?.extended_area > 0 && (
                    <Row>
                      <Col>
                        <span>Zona Extendida</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.extended_area)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {rate.shipping_rates?.excess_weight > 0 && (
                    <Row>
                      <Col>
                        <span>Exceso de Peso</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.excess_weight)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {rate.shipping_rates?.fuel > 0 && (
                    <Row>
                      <Col>
                        <span>Combustible</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.fuel)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  {rate.shipping_rates?.insurance > 0 && (
                    <Row>
                      <Col>
                        <span>Cargo por Seguro</span>
                      </Col>
                      <Col className="d-flex justify-content-end align-items-end">
                        <span className="fw-medium align-items-end">
                          <span className="text-small text-muted">$ </span>
                          {fmDecimal.from(rate.shipping_rates?.insurance)}
                        </span>
                      </Col>
                    </Row>
                  )}
                  <hr className="my-1 mt-2 d-xxl-none" />
                  <Row className="mt-sm-2">
                    <Col xs="auto" className="ms-auto ps-3 text-muted">
                      Subtotal
                    </Col>
                    {/* <Col>
                      <span>Subtotal:</span>
                    </Col> */}
                    <Col xs="auto" className="sw-13 text-end">
                      <span>
                        <span className="text-small text-muted">$ </span>
                        {fmDecimal.from(rate.shipping_rates?.subtotal)}
                      </span>
                    </Col>
                    {/* <Col className="d-flex justify-content-end align-items-end">
                      <span className="fw-medium align-items-end">
                        <span className="text-small text-muted">$ </span>
                        {fmDecimal.from(rate.shipping_rates?.subtotal)}
                      </span>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col xs="auto" className="ms-auto ps-3 text-muted">
                      IVA
                    </Col>
                    <Col xs="auto" className="sw-13 text-end">
                      <span>
                        <span className="text-small text-muted">$ </span>
                        {fmDecimal.from(rate.shipping_rates?.iva)}
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="auto" className="mt-1 ms-auto">
                      <h5 style={{ fontWeight: 'bold', marginBottom: '0' }}>Total</h5>
                    </Col>
                    {/* fw-bolder fw-medium */}
                    <Col xs="auto" className="sw-13 text-end text-primary">
                      <h4 style={{ fontWeight: 'bold', marginBottom: '0' }}>
                        <span className="text-small text-muted">$ </span>
                        {fmDecimal.from(rate.shipping_rates?.total)}
                      </h4>
                    </Col>
                  </Row>
                </div>
                <Button
                  className="btn-icon btn-icon-end w-100 align-self-end mt-2 ms-5"
                  style={{ maxWidth: '180px', marginBottom: '5px' }}
                  variant="primary"
                  disabled={isButtonDisabled}
                  onClick={() => {
                    if (page === 'quote') {
                      goShippingForm();
                    } else {
                      handlerShipping(rate.shipping_code);
                    }
                  }}
                >
                  <span>Crear envio</span>
                  <CsLineIcons icon="chevron-right" />
                </Button>
              </Col>
              {/* - Precios Card End  */}
            </Row>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default SupplierCard;
