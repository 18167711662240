import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Dropdown, Card } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRateList, editUserAccount } from '../../../api/Api-admin';

const EditUser = ({ user }) => {
  const [isAutoBalance, setIsAutoBalance] = useState(user.is_update_balance);
  const [isAutoVouchers, setIsAutoVouchers] = useState(false);
  const [isMultiPieces, setIsMultiPieces] = useState(user.is_multipiece_available);
  const [isAccountActive, setIsAccountActive] = useState(user.is_active);
  const [customerType, setCustomerType] = useState(user.customer_type);
  const [seller, setSeller] = useState(user.seller);
  const [rateMethod, setRateMethod] = useState(user.rates_list_method);
  const [labelType, setLabelType] = useState(user.label_type === null ? '-- x --' : user.label_type);
  const [ratesList, setRatesList] = useState([]);
  const [rate, setRate] = useState(user.rates_list === null ? '-- x --' : user.rates_list);
  const [dataToUpdate, setDataToUpdate] = useState({
    crmId: user.crm_user_id,
    environment: 'PRODUCTION',
    email: user.email,
    razonSocial: user.legal_name,
    rateMethod,
    rateType: rate,
    labelType,
    isAccountActive,
  });

  const handlerEditUserAccount = () => {
    // console.log('datos para editar:', user.crm_user_id, dataToUpdate);
    editUserAccount(user.crm_user_id, dataToUpdate)
      .then((response) => {
        // console.log('datos editar response:', response);
        if (response.message) {
          toast.success('Los cambios se han guardado correctamente.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      })
      .catch((err) => {
        console.log('datos editar err:', err);
      });
  };
  useEffect(() => {
    setDataToUpdate((prevState) => ({
      ...prevState,
      rateMethod,
      rateType: rate,
      labelType,
      isAccountActive,
    }));
  }, [rateMethod, rate, labelType, isAccountActive]);

  useEffect(() => {
    getRateList().then((response) => {
      console.log('get rates:', response);
      if (response.rates_list) {
        setRatesList(response.rates_list);
      } else {
        toast.error('Error: No fue posible obtener lista de tarifarios', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    });
  }, []);
  return (
    <Card className="mb-5 mt-0 mt-lg-0 pt-lg-2">
      <Card.Body className="h-100">
        <Row className="g-0 bg-infok">
          <Col xs="12" md="6" xl="12" className="bg-successx d-flex flex-column justify-content-start">
            <div className="d-flex align-items-center mb-2">
              {user.is_active ? (
                <CsLineIcons icon="activity" size="19" className="text-muted" />
              ) : (
                <CsLineIcons icon="close-circle" size="19" className="text-muted" />
              )}
              <div className="text-alternate mx-2">CUENTA {user.is_active ? 'ACTIVA' : 'INACTIVA'} </div>
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                checked={isAccountActive}
                onClick={() => setIsAccountActive(!isAccountActive)}
              />
              <span className="ms-1 text-primary">*</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <CsLineIcons icon="dollar" size="19" className="text-muted" />
              <div className="text-alternate mx-2">SALDO AUTOMATICO</div>
              <input
                type="checkbox"
                className="form-check-input cursor-pointer ms-2  mb-1"
                checked={isAutoBalance}
                onClick={() => setIsAutoBalance(!isAutoBalance)}
              />
              <span className="ms-1 text-primary">x</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <CsLineIcons icon="invoice" size="19" className="text-muted" />
              <div className="text-alternate mx-2">COMPROBANTES FISCALES</div>
              <input
                type="checkbox"
                className="form-check-input cursor-pointer ms-2  mb-1"
                checked={isAutoVouchers}
                onClick={() => setIsAutoVouchers(!isAutoVouchers)}
              />
              <span className="ms-1 text-primary">x</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <CsLineIcons icon="duplicate" size="19" className="text-muted" />
              <div className="text-alternate mx-2">MULTIPIEZAS</div>
              <input
                type="checkbox"
                className="form-check-input cursor-pointer ms-2 mb-1"
                checked={isMultiPieces}
                onClick={() => setIsMultiPieces(!isMultiPieces)}
              />
              <span className="ms-1 text-primary">x</span>
            </div>
          </Col>
          <Col xs="12" md="6"  xl="12" className="bg-warningx d-flex flex-column justify-content-start">
            <div className="d-flex align-items-center mb-2">
              <CsLineIcons icon="shield" size="19" className="text-muted" />
              <div className="text-alternate mx-2"> CLIENTE TIPO </div>
              <Dropdown>
                <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                  {customerType}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-start">
                  <Dropdown.Item className={`${customerType === 'T' && 'd-none'}`} onClick={(e) => setCustomerType(e.target.innerText)}>
                    T
                  </Dropdown.Item>
                  <Dropdown.Item className={`${customerType === 'C' && 'd-none'}`} onClick={(e) => setCustomerType(e.target.innerText)}>
                    {' '}
                    C
                  </Dropdown.Item>
                  <Dropdown.Item className={`${customerType === 'D' && 'd-none'}`} onClick={(e) => setCustomerType(e.target.innerText)}>
                    {' '}
                    D{' '}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span className="ms-1 text-primary">x</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <CsLineIcons icon="support" size="19" className="text-muted" />
              <div className="text-alternate mx-2"> ASESOR </div>
              <Dropdown>
                <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                  {seller}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-start">
                  <Dropdown.Item className={`${seller === 'V000' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                    {' '}
                    V000
                  </Dropdown.Item>
                  <Dropdown.Item className={`${seller === 'V002' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                    {' '}
                    V002
                  </Dropdown.Item>
                  <Dropdown.Item className={`${seller === 'V003' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                    {' '}
                    V003{' '}
                  </Dropdown.Item>
                  <Dropdown.Item className={`${seller === 'V004' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                    {' '}
                    V004
                  </Dropdown.Item>
                  <Dropdown.Item className={`${seller === 'V005' && 'd-none'}`} onClick={(e) => setSeller(e.target.innerText)}>
                    {' '}
                    V005{' '}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span className="ms-1 text-primary">x</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              <CsLineIcons icon="code" size="19" className="text-muted" />
              <div className="text-alternate mx-2"> METODO TARIFARIO </div>
              <Dropdown>
                <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                  {rateMethod}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-start">
                  <Dropdown.Item className={`${rateMethod === 'API' && 'd-none'}`} onClick={(e) => setRateMethod(e.target.innerText)}>
                    API
                  </Dropdown.Item>
                  <Dropdown.Item className={`${rateMethod === 'FIREBASE' && 'd-none'}`} onClick={(e) => setRateMethod(e.target.innerText)}>
                    {' '}
                    FIREBASE
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span className="ms-1 text-primary">*</span>
            </div>

            <div className="d-flex align-items-center mb-2">
              {user.rates_list !== null ? (
                <CsLineIcons icon="star" size="19" className="text-muted" />
              ) : (
                <CsLineIcons icon="close-circle" size="19" className="text-muted" />
              )}
              <div className="text-alternate mx-2"> TARIFARIO </div>
              <Dropdown>
                <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                  {rate}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-start" style={{ height: '200px', overflowY: 'scroll' }}>
                  {ratesList?.map((rates) => (
                    <Dropdown.Item key={rates.id} onClick={() => setRate(rates.name)}>
                      {rates.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <span className="ms-1 text-primary">*</span>
            </div>
            <div className="d-flex align-items-center mb-2">
              {user.label_type !== null ? (
                <CsLineIcons icon="print" size="19" className="text-muted" />
              ) : (
                <CsLineIcons icon="close-circle" size="19" className="text-muted" />
              )}
              <div className="text-alternate mx-2"> ETIQUETA </div>
              <Dropdown>
                <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1">
                  {labelType}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-start">
                  <Dropdown.Item className={`${rateMethod === 'COMPACT' && 'd-none'}`} onClick={(e) => setLabelType(e.target.innerText)}>
                    COMPACT
                  </Dropdown.Item>
                  <Dropdown.Item className={`${rateMethod === 'STANDARD' && 'd-none'}`} onClick={(e) => setLabelType(e.target.innerText)}>
                    STANDARD
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span className="ms-1 text-primary">*</span>
            </div>
          </Col>
          <div className="ms-1 text-primary">* Disponible para editar</div>

          <Col xs="12" className="bg-mutedx d-flex justify-content-end align-items-end">
            <Button variant="primary" className="btn-icon btn-icon-start w-auto mt-3 mt-lg-1" onClick={handlerEditUserAccount}>
              <CsLineIcons icon="edit" /> <span> Editar </span>
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EditUser;
