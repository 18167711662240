import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'react-bootstrap';
// External libraries
// api
import { getStatements } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import StatementCard from './components/StatementCard';
import StatementRow from './components/StatementRow';

const LastStatements = ({ crmId, userName, updatePage }) => {
  const [statements, setStatements] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlerGetStatements = () => {
    getStatements(crmId, '', 1, 5).then((response) => {
      // console.log('Get statements response and restartState ejecutado:', response);
      setIsLoading(false);
      setStatements(response.statement);
    });
  };
  useEffect(() => {
    handlerGetStatements();
  }, [updatePage]);

  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {statements?.length === 0 ? (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>No hay movimientos registrados</h3>
        </Row>
      ) : (
        <>
          {statements?.length > 0 && (
            <NavLink to={`/users-all/statements/${crmId}/${userName}`} className="mb-3 cursor-pointer text-decoration-underline text-end">
              Ver todos
            </NavLink>
          )}
          {/* List Header Start */}
          {statements?.length > 0 && <StatementRow />}
          {/* List Header End */}
          {/* List Items Start */}
          {statements?.map((statement, index) => (
            <StatementCard data={statement} key={index} />
          ))}
          {/* List Items End */}
        </>
      )}
    </>
  );
};

export default LastStatements;
