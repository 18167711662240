import { createSlice } from '@reduxjs/toolkit';
// "parcel": { "height": 1, "width": 5, "depth": 5, "weight": 1, "product_value": 0 }
const initialState = {
  parcelData: {
    fields: {
      name: '',
      product_description: '',
      height: '',
      width: '',
      depth: '',
      weight: '',
      product_value: 0, // this value is sent int the request
      // parcel: { height: '', width: '', depth: '', weight: '', product_value: 0 },
      product_value_shipping: null, // this value is for the view only
      insurance: false,
      multi_pieces: 1,
    },
    errors: {
      name: false,
      product_description: false,
      // parcel: '',
      height: false,
      width: false,
      depth: false,
      weight: false,
      product_value: false,
      insurance: false,
      product_value_shipping: false,
    },
    valid: {
      name: false,
      product_description: false,
      parcel: false,
      height: false,
      width: false,
      depth: false,
      weight: false,
      product_value_shipping: false,
      insurance: false,
    },
  },
};

const parcelDataSlice = createSlice({
  name: 'parcelData',
  initialState,
  reducers: {
    setParcelData(state, action) {
      const { point, data } = action.payload;
      state.parcelData.fields[point] = data;
    },
    // setParcel(state, action) {
    //   const { point, data } = action.payload;
    //   state.parcelData.fields.parcel[point] = data;
    // },
    setParcelDataError(state, action) {
      const { point, data } = action.payload;
      state.parcelData.errors[point] = data;
    },
    setParcelDataValidate(state, action) {
      const { point, data } = action.payload;
      state.parcelData.valid[point] = data;
    },
    clearParcelForm: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});
export const { setParcelData, setParcelDataError, setParcelDataValidate, clearParcelForm } = parcelDataSlice.actions; // imported in enviar.js
const ParcelDataReducer = parcelDataSlice.reducer; // store.js set up

export default ParcelDataReducer;
