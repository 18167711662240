import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  addressDetail: null,
  // editAddress: null,
};

const addressDataSlice = createSlice({
  name: 'addressDetail',
  initialState,
  reducers: {
    setAddressDetail(state, action) {
      state.addressDetail = action.payload;
    },

    // setEditAddress(state, action) {
    //   state.editAddress = action.payload;
    // },
  },
});

export const { setAddressDetail } = addressDataSlice.actions; // imported in ----.js
const addressDataReducer = addressDataSlice.reducer; // store.js set up

export default addressDataReducer;
