import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';

const CardOrder = ({ order }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-8">
        <Col className="py-5 py-lg-0 ps-5 pe-5 h-100 ">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" sm="8" lg="3" className="d-flex flex-column justify-content-center  order-1 ">
              <div className="text-muted text-small d-lg-none">ID Orden</div>
              <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Detalle de orden</Tooltip>}>
                <NavLink className="anchor-effect-primary" to={`/envios/ordenes/detalle/${order.order_id}`}>
                  {order.order_id}
                </NavLink>
              </OverlayTrigger>
            </Col>
            <Col xs="13" sm="4" lg="3" className="d-flex flex-column justify-content-center align-items-end align-items-lg-start  order-2 ">
              <div className="text-muted text-small d-lg-none">Fecha / Hora</div>
              <div className="text-alternate">{`${moment(order.creation_date).utcOffset('-06:00').format('DD-MM-YY HH:mm')}`}</div>
            </Col>
            <Col xs="12" className="bg-success order-3 invisible d-lg-none ">
              -
            </Col>
            <Col xs="6" sm="2" lg="2" className="d-flex flex-column justify-content-center align-items-lg-center  order-4 ">
              <div className="text-muted text-small d-lg-none">Envíos</div>
              <div className="text-alternate pe-4"> {order.requested_shippings}</div>
            </Col>
            <Col xs="6" sm="2" lg="2" className="d-flex flex-column justify-content-center align-items-lg-center  order-5 ">
              <div className="text-muted text-small d-lg-none">Creados</div>
              <div className="text-alternate pe-2">{order.completed_shippings}</div>
            </Col>

            <Col xs="8" className="bg-success d-none d-sm-flex order-last  invisible d-lg-none">
              -
            </Col>

            <Col xs="6" sm="2" lg="1" className="d-flex flex-column justify-content-center align-items-lg-center order-last ">
              <div className="text-muted text-small d-lg-none">Pendientes</div>
              <div className="text-alternate pe-2">{order.pending_shippings}</div>
            </Col>
            <Col xs="6" sm="2" lg="1" className="d-flex flex-column justify-content-center align-items-lg-end order-last ">
              <div className="text-muted text-small d-lg-none mb-1">Errores</div>
              <div className="text-alternate">{order.failed_shippings}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default CardOrder;
