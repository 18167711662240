import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Button, Card, Form, InputGroup } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
// api
import { getZipCode, editAddress } from '../../../../api/Api-user';

const EditAddress = () => {
  const title = 'Editar dirección';
  const description = 'Ecommerce Customer Detail Page';
  const history = useHistory();
  // se tiene que cambiar a pagina dinamica con el identificador del domicilio en el params
  // - Global State from Store.js form Values
  const { addressDetail } = useSelector((state) => state.address_data);
  // console.log('Direccion detalle :', addressDetail);
  // Local State
  const [addressTypeSend, setAddressTypeSend] = useState(false);
  const [addressTypeReceive, setAddressTypeReceive] = useState(false);
  // remove accents
  const cleanData = (str) => {
    // Quitar acentos
    let cleanedStr = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    // Eliminar caracteres especiales, dejando solo letras y números
    cleanedStr = cleanedStr.replace(/[^a-zA-Z0-9 ]/g, '');
    return cleanedStr;
  };
  const [address, setAddress] = useState({
    name: cleanData(addressDetail.name).substr(0, 29),
    streetNumber: cleanData(addressDetail.street_number).substr(0, 10),
    company: cleanData(addressDetail.company).substr(0, 29),
    phone: addressDetail.phone,
    street: cleanData(addressDetail.street).substr(0, 50),
    zip: addressDetail.zip_code,
    email: addressDetail.email,
    reference: cleanData(addressDetail.reference).substr(0, 20),
    neighborhood: cleanData(addressDetail.neighborhood).substr(0, 35),
    city: '',
    state: '',
    addressType: addressDetail.address_type, // can be RECEIVER or SEND
  });
  const [addressError, setAddressError] = useState({
    name: false,
    streetNumber: false,
    company: false,
    phone: false,
    street: false,
    zip: '',
    email: false,
    reference: false,
    neighborhood: false,
    city: '',
    state: '',
    addressType: '',
  });
  // Set address type
  useEffect(() => {
    if (addressDetail.address_type === 'RECEIVE') {
      setAddressTypeReceive(true);
      // setAddress({...address, addressType: 'RECEIVE'})
    } else {
      setAddressTypeSend(true);
      // setAddress({...address, addressType: 'SEND'})
    }
  }, []);

  // - Data Validation and Updating States with Reducers
  const updateData = (type, data) => {
    if (type === 'phone' || type === 'zip') {
      if (data === '' || /^[0-9]+$/i.test(data)) {
        setAddress({ ...address, [type]: data });
      }
    } else {
      const cleanedData = cleanData(data);
      setAddress({ ...address, [type]: cleanedData });
    }
  };
  const validationAddressesSchema = Yup.object().shape({
    // email, refernce and company are not required
    name: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    street: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    streetNumber: Yup.string().required('Campo requerido'),
    city: Yup.string().required('Ingresa un código postal valido'),
    neighborhood: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    zip: Yup.string().min(5, 'CP debe tener 5 caracteres').required('Campo requerido'),
    phone: Yup.string().min(10, 'Este campo debe tener 10 caracteres').required('Campo requerido'),
    email: Yup.string().email('El correo electrónico no es válido'),
    // reference: Yup.string().min(10, 'Este campo debe tener mínimo 3 caracteres'),
    // company: Yup.string().min(10, 'Este campo debe tener  mínimo 3 caracteres'),
  });

  useEffect(() => {
    // - useEffect observes if zip code has 5 digits and executes getZipCode
    // - getZipCode received the zip code and makes the request to the microservices and finally updates the data
    setAddressError({ ...addressError, zip: '' });
    if (address.zip.length === 5) {
      getZipCode(address.zip)
        .then((response) => {
          // console.log(response);
          if (response?.length > 0) {
            // setAddressError({ ...addressError, zip: '' });
            setAddress({ ...address, state: response[0].state.name, city: response[0].city });
          } else {
            setAddressError({ ...addressError, zip: 'Código invalido' });
          }
        })
        .catch(() => {
          setAddressError({ ...addressError, zip: 'Compruebe su conexión a Internet' });
        });
    } else {
      // when user is typing
      setAddressError({ ...addressError, zip: '' });
      setAddress({ ...address, state: '', city: '' });
    }
  }, [address.zip]);
  // - Edit address
  const handlerEditAddress = () => {
    // console.log("####", address)
    editAddress(addressDetail.id, address)
      .then((response) => {
        let msgErr = '';
        // console.log('response editar:', response);
        if (response.id !== undefined) {
          history.push('/ajustes/direcciones');
          toast.success('¡Se editó dirección correctamente!', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        } else if (response.data.error.address) {
          // to get an err send an incorrect id address.id
          response.data.error.address.forEach((msg) => {
            msgErr = `${msg}. Código:  ${response.data.error_code}`;
          });
          toast.error(msgErr, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Ocurrio un error', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      });
  };
  const validateForm = async (e) => {
    e.preventDefault();

    // - Address  validations
    try {
      await validationAddressesSchema.validate(address, { abortEarly: false });
      // How last validation edits only if CP is valid
      if (!addressError.zip) {
        handlerEditAddress();
      }
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        // setAddressErr({ ...addressErr, [error.path]: error.message });
        formErrors[error.path] = error.message;
      });
      // console.log('errores en addres from:', formErrors);
      setAddressError(formErrors);
      // scrollRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
      // || formErrors.company || formErrors.reference
      if (formErrors.email) {
        toast.error('Por favor, revisa y corrige los campos marcados.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else if (addressError.zip) {
        toast.error('Código Postal invalido.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      } else {
        toast.error('Por favor, completa todos los campos obligatorios.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    }
  };
  // console.log("Direccion a editar:", address)
  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/ajustes/direcciones">
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Atras</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {/* Title End */}
        </Row>
      </div>

      <Form onSubmit={validateForm}>
        {/* Top Buttons Start */}
        <Col xs="12" lg="10" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0">
          <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
            <CsLineIcons icon="save" /> <span>Guardar</span>
          </Button>
        </Col>
        {/* Top Buttons End */}
        {/* <Row> */}
        <Col xs="12" lg="10">
          {/* Personal Info  Start */}
          <h2 className="small-title mt-3">Información personal</h2>
          <div className="mb-5">
            <Card className="mb-2">
              <Card.Body style={{ fontFamily: 'Montserrat' }}>
                <Form>
                  <Row className="g-3">
                    <Col lg="6" className="position-relative">
                      <Form.Label>
                        Nombre <span className="text-primary">*</span>
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="user" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          maxLength={29}
                          placeholder="máximo 29 caracteres"
                          type="text"
                          value={address.name}
                          onChange={(e) => {
                            setAddressError({ ...addressError, name: false });
                            updateData('name', e.target.value);
                          }}
                        />
                      </InputGroup>

                      {addressError.name && <div className={`invalid-tooltip ${addressError.name ? 'd-block' : 'd-none'}`}>{addressError.name}</div>}
                    </Col>
                    <Col lg="6" className="position-relative">
                      <Form.Label>Empresa</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="building" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          maxLength={29}
                          placeholder="máximo 29 caracteres"
                          type="text"
                          value={address.company}
                          onChange={(e) => {
                            setAddressError({ ...addressError, company: false });
                            updateData('company', e.target.value);
                          }}
                        />
                      </InputGroup>

                      {addressError.company && <div className={`invalid-tooltip ${addressError.company ? 'd-block' : 'd-none'}`}>{addressError.company}</div>}
                    </Col>
                    <Col sm="4" lg="4" className="position-relative">
                      <Form.Label>Telefono</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="phone" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          maxLength={10}
                          placeholder="10 dígitos"
                          value={address.phone}
                          onChange={(e) => {
                            setAddressError({ ...addressError, phone: false });
                            updateData('phone', e.target.value);
                          }}
                        />
                      </InputGroup>

                      {addressError.phone && <div className={`invalid-tooltip ${addressError.phone ? 'd-block' : 'd-none'}`}>{addressError.phone}</div>}
                    </Col>
                    <Col sm="8" lg="6" className="position-relative">
                      <Form.Label>Correo</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="email" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type="email"
                          placeholder="name@example.com"
                          value={address.email}
                          onChange={(e) => {
                            setAddressError({ ...addressError, email: '' });
                            setAddress({ ...address, email: e.target.value });
                          }}
                        />
                      </InputGroup>

                      {addressError.email && <div className={`invalid-tooltip ${addressError.email ? 'd-block' : 'd-none'}`}>{addressError.email}</div>}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
          {/* Personal Info  End */}

          {/* Address Detail Start */}
          <h2 className="small-title">Detalles domicilio</h2>
          <div className="mb-5">
            <Card className="mb-2">
              <Card.Body>
                <Form>
                  <Row className="g-3">
                    <Col xs="12" lg="8" className="position-relative">
                      <Form.Label>Calle</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="home" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          maxLength={50}
                          placeholder="máximo 50 caracteres"
                          type="text"
                          value={address.street}
                          onChange={(e) => {
                            setAddressError({ ...addressError, street: false });
                            updateData('street', e.target.value);
                          }}
                        />
                      </InputGroup>

                      {addressError.street && <div className={`invalid-tooltip ${addressError.street ? 'd-block' : 'd-none'}`}>{addressError.street}</div>}
                    </Col>
                    <Col sm="3" lg="2" className="position-relative">
                      <Form.Label>Número</Form.Label>
                      <span className="text-primary">*</span>
                      <Form.Control
                        required
                        maxLength={10}
                        type="text"
                        placeholder="&#x23;"
                        value={address.streetNumber}
                        onChange={(e) => {
                          setAddressError({ ...addressError, streetNumber: false });
                          updateData('streetNumber', e.target.value);
                        }}
                      />
                      {addressError.streetNumber && (
                        <div className={`invalid-tooltip ${addressError.streetNumber ? 'd-block' : 'd-none'}`}>{addressError.streetNumber}</div>
                      )}
                    </Col>
                    <Col sm="9" lg="8" className="position-relative">
                      <Form.Label>Colonia</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="home" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          maxLength={35}
                          placeholder="máximo 35 caracteres"
                          type="text"
                          value={address.neighborhood}
                          onChange={(e) => {
                            setAddressError({ ...addressError, neighborhood: false });
                            updateData('neighborhood', e.target.value);
                          }}
                        />
                      </InputGroup>
                      {addressError.neighborhood && (
                        <div className={`invalid-tooltip ${addressError.neighborhood ? 'd-block' : 'd-none'}`}>{addressError.neighborhood}</div>
                      )}
                    </Col>
                    <Col sm="3" lg="2" className="position-relative">
                      <Form.Label>Código postal</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="pin" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          value={address.zip}
                          placeholder="Código postal"
                          maxLength={5}
                          onChange={(e) => {
                            updateData('zip', e.target.value);
                          }}
                        />
                      </InputGroup>
                      {addressError.zip && <div className={`invalid-tooltip ${addressError.zip ? 'd-block' : 'd-none'}`}>{addressError.zip}</div>}
                    </Col>
                    <Col sm="4" lg="3">
                      <Form.Label>Ciudad</Form.Label>
                      <Form.Control type="text" readOnly value={address.city} />
                    </Col>
                    <Col sm="5" lg="3">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control type="text" readOnly value={address.state} />
                    </Col>
                    <Col lg="6" xxl="6" className="position-relative">
                      <Form.Label>Referencias</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="destination" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          maxLength={20}
                          placeholder="máximo 20 caracteres"
                          type="text"
                          value={address.reference}
                          onChange={(e) => {
                            setAddressError({ ...addressError, reference: false });
                            updateData('reference', e.target.value);
                          }}
                        />
                      </InputGroup>
                      {addressError.reference && (
                        <div className={`invalid-tooltip ${addressError.reference ? 'd-block' : 'd-none'}`}>{addressError.reference}</div>
                      )}
                    </Col>
                    <Col>
                      <Form.Label>Tipo de dirección</Form.Label>
                      <div style={{ position: 'relative', zIndex: 1, maxWidth: '200px' }}>
                        <div className="ms-2">
                          {/* address from */}
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={addressTypeSend}
                            onClick={() => {
                              setAddressTypeSend(!addressTypeSend);
                              setAddress({ ...address, addressType: 'SEND' });
                              // setAddressErr({ ...addressErr, addressType: false });
                              setAddressTypeReceive(false);
                            }}
                          />
                          <label className="form-label">Origen</label>
                        </div>
                        <div className="ms-2">
                          {/* address to */}
                          <input
                            type="checkbox"
                            className="form-check-input me-2"
                            checked={addressTypeReceive}
                            onClick={() => {
                              setAddressTypeReceive(!addressTypeReceive);
                              setAddress({ ...address, addressType: 'RECEIVE' });
                              // setAddressErr({ ...addressErr, addressType: false });
                              setAddressTypeSend(false);
                            }}
                          />
                          <label className="form-label">Destino</label>
                        </div>
                        {/* {addressErr.addressType && (
                        <div className={`invalid-tooltip ${addressErr.addressType ? 'd-block' : 'd-none'}`}>{addressErr.addressType}</div>
                      )} */}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </div>
          {/* Address Detail End */}
        </Col>
        {/* </Row> */}
      </Form>
    </>
  );
};

export default EditAddress;
