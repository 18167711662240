import React from 'react';
import { CSVLink } from 'react-csv';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// external libraries
import moment from 'moment';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const RepsCSV = ({ reps }) => {
  const csvData = reps.map((rep) => ({
    date: moment(rep.created).format('DD-MM-YY'),
    legalName: rep.legal_name.toUpperCase(),
    monto: fmDecimal.from(rep.amount, { symbol: '$' }),
    ...rep,
  }));
  const headers = [
    { label: 'Fecha', key: 'date' },
    { label: 'Folio', key: 'folio_number' },
    { label: 'Razon social', key: 'legalName' },
    { label: 'Monto', key: 'monto' },
    { label: 'Estado', key: 'status' },
  ];
  return (
    <CSVLink data={csvData} headers={headers} className="btn btn-primary" filename="Historial Reps.csv" style={{ height: '36px' }} target="_blank">
      <span>
        <CsLineIcons icon="download" size="15" className="mb-1" /> Excel{' '}
      </span>
    </CSVLink>
  );
};
export default RepsCSV;
