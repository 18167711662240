import React from 'react';
import { CSVLink } from 'react-csv';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// external libraries
import moment from 'moment';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const ReceiptsCSV = ({ receipts }) => {
  const csvData = receipts.map((receipt) => ({
    date: moment(receipt.created).format('DD-MM-YY'),
    legalName: receipt.legal_name.toUpperCase(),
    monto: fmDecimal.from(receipt.amount, { symbol: '$' }),
    ...receipt,
  }));

  const headers = [
    { label: 'Fecha', key: 'date' },
    { label: 'Folio', key: 'folio_number' },
    { label: 'Razon social', key: 'legalName' },
    { label: 'Monto', key: 'monto' },
  ];
  return (
    <CSVLink data={csvData} headers={headers} className="btn btn-primary" filename="Historial Recibos.csv" style={{ height: '36px' }} target="_blank">
      <span>
        <CsLineIcons icon="download" size="15" className="mb-1" /> Excel{' '}
      </span>
    </CSVLink>
  );
};
export default ReceiptsCSV;
