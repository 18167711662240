import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// components
import CardStatement from './components/CardStatement';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import StatementsCSV from '../../../components/reactCsv/StatementsCSV';
import Paginate from '../../../components/Paginate';

// Api
import { getStatementsHistory } from '../../../api/Api-user';

const Statements = () => {
  const title = 'Movimientos';
  const description = 'Movimientos Plataforma Autopaquete';
  const { currentUser } = useSelector((state) => state.auth);
  // -Local States
  const [statements, setStatements] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // const { currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    const handlerGetStatements = async (page, items) => {
      try {
        const result = await getStatementsHistory(page, items);
        // console.log('result', result);
        setIsLoading(false);
        setStatements(result.statement);
        // setStatements([]);
        setTotalPages(result.total_pages);
        startRef.current.scrollIntoView({ behavior: 'smooth' });

        if (page > result.total_pages) setCurrentPage(0);
      } catch (error) {
        // console.log('error en catch statement history:', error);
      }
    };
    handlerGetStatements(currentPage, pageSize);
  }, [pageSize, currentPage]);

  const settingPage = (limit) => {
    setIsLoading(true);
    setCurrentPage(1);
    setPageSize(limit);
  };
  // console.log('Statemens:', statements);
  return (
    <>
      {statements === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </div>

        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row>
            <Col className="mb-1 text-end">
              {/* Excel Buttons  */}
              {statements?.length > 0 && <StatementsCSV statements={statements} crmId="" />}
              {/* Dropdown  */}
              <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-2 ${statements?.length === 0 && 'invisible'}`}>
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                  {`${pageSize} Movimientos`}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow dropdown-menu-end">
                  <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                    10 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                    20 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                    50 Movimientos
                  </Dropdown.Item>
                  <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                    100 Movimientos
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className={`${statements?.length === 0 && 'invisible'} text-muted my-3 d-lg-flex`}>{`Página ${currentPage}`}</div>
            </Col>
          </Row>
          {/* 
          <Row className="g-0 mb-3">
            <Col className={`mb-1 ${statements?.length === 0 && 'd-none'} d-flex justify-content-end justify-content-lg-start`}>
              <div className="text-muted mt-3  d-lg-flex">{`Página ${currentPage}`}</div>
            </Col>
          </Row> */}
          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort">
            <Col md="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
              <div className="text-muted text-small cursor-pointer">CONCEPTO</div>
            </Col>
            <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">FECHA MOVIMIENTO</div>
            </Col>
            <Col md="3" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointeR">REFERENCIA</div>
            </Col>
            <Col md="2" className="invisible">
              -
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">MONTO</div>
            </Col>
            <Col md="1" className="d-flex flex-column pe-1 justify-content-center text-end">
              <div className="text-muted text-small cursor-pointer">SALDO</div>
            </Col>
          </Row>
          {/* List Header End */}
          {/* List Items Start */}
          {statements?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center my-5">
                Aún no hay movimientos registrados en tu historial. Una vez que realices transacciones o envíos, podrás ver el resumen aquí.
              </h3>
              <NavLink to="/envios/enviar" className="btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                <CsLineIcons icon="plus" /> <span>Crear envío</span>
              </NavLink>
            </div>
          ) : (
            <>
              {/* List statements Items Start */}
              {statements?.map((statement) => (
                <CardStatement key={statement.id} statement={statement} />
              ))}

              {/* List statements Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Statements;
