import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Pagination, Modal, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { VerticalNavigation, VerticalSection, VerticalItem } from 'react-rainbow-components';
import styled from 'styled-components';
// components
import LoadingCar from '../../../components/loading/LoadingCar';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import ModalParcel from './components/ModalParcel';
import CardParcel from './components/CardParcel';
import Paginate from '../../../components/Paginate';
// api
import { getParcels, getParcelDirectory } from '../../../api/Api-user';

const Parcels = () => {
  const title = 'Paquetes';
  const description = 'Paquetes Plataforma Autopaquete';
  // - Local States
  const [directory, setDirectory] = useState(false);
  const [parcelList, setParcelList] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectedParcel, setIsSelectedParcel] = useState(false);
  const [parcelNameToSearch, setParcelNameToSearch] = useState('');
  // paginate
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // - Use references to do scroll Up
  const startRef = useRef(null);
  const StyledContainer = styled.div`
    min-width: 220px;
    margin: 5px;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    // REVISAR PORQUE NO TOMA ESTAS PROPIEDADES
    // position: 'absolute';
    // background-color: '#ffffff';
    // z-index: 100;
    > nav > div > ul > li > button {
      // background: salmon;
      padding: 0.5rem;
    }
  `;

  const handlerGetParcels = async (page, items) => {
    setTimeout(function () {
      setIsSelectedParcel(false); // remove searched address
    }, 1000);
    setParcelNameToSearch('');
    try {
      const result = await getParcels(page, items);
      // console.log('result', result);
      setParcelList(result.parcels);
      // setParcelList([]);
      setTotalPages(result.total_pages);
      setIsLoading(false);
    } catch (error) {
      // console.log('error en getAddresses catch :', error);
    }
  };
  useEffect(() => {
    handlerGetParcels(currentPage, pageSize);
  }, [isModalOpen, currentPage, pageSize]);
  // console.log(parcelList);
  const settingPage = (limit) => {
    setIsLoading(true);
    setCurrentPage(1);
    setPageSize(limit);
  };
  const handlerDirectory = (string) => {
    // console.log('string:', string);
    getParcelDirectory(string).then((result) => {
      // console.log('resultado peticion addresss directory:', result);
      if (result.parcels?.length >= 1) {
        setDirectory(result.parcels);
      }
    });
  };
  const showParcel = (parcel) => {
    // console.log('direccion seleccionada:',parcel)
    setDirectory(false);
    setIsSelectedParcel(true);
    setParcelList([parcel]);
  };
  return (
    <>
      {parcelList === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer" onClick={() => setDirectory(false)}>
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0 mb-3 ">
            {/* Title Start col-auto */}
            <Col xs="auto" className="col-auto mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* button Add Address Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mt-3 mb-sm-0">
              <Button href="/ajustes/paquetes/agregar" variant="outline-primary" className="btn-icon w-100">
                <CsLineIcons icon="plus" /> <span> Agregar </span>
              </Button>
            </Col>
            {/* button Add Address End */}
          </Row>
        </div>
        <Row className="mb-1">
          <Col md="5" lg="3" xxl="2" className="mb-1">
            {/* Search Start */}
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground">
              <Form.Control
                type="text"
                placeholder="Buscar por nombre"
                value={parcelNameToSearch}
                onChange={(e) => {
                  handlerDirectory(e.target.value);
                  setParcelNameToSearch(e.target.value);
                }}
              />
              {!isSelectedParcel ? (
                <span className="search-magnifier-icon">
                  <CsLineIcons icon="search" />
                </span>
              ) : (
                <span className="search-delete-icon" onClick={() => handlerGetParcels(currentPage, pageSize)}>
                  <CsLineIcons icon="close" />
                </span>
              )}
            </div>
            {/* Search End */}
            {/* - Address List Start */}
            {directory && (
              <StyledContainer style={{ position: 'absolute', zIndex: 100, backgroundColor: '#ffffff', marginTop: '40px' }}>
                <VerticalNavigation>
                  <VerticalSection>
                    {directory.map((parcel) => (
                      <VerticalItem key={parcel.id} name={parcel.name} label={parcel.name} onClick={() => showParcel(parcel)} />
                    ))}
                  </VerticalSection>
                </VerticalNavigation>
              </StyledContainer>
            )}
            {/* - Address List End */}
          </Col>
          <Col md="7" lg="9" xxl="10" className="mb-1  text-end">
            {/* Length Start */}
            <Dropdown align={{ xs: 'end' }} className="mb-2 d-inline-block ms-1">
              <Dropdown.Toggle variant="foreground-alternate" className={`shadow sw-18 ${parcelList?.length === 0 || (isSelectedParcel && 'invisible')}`}>
                {`${pageSize} Paquetes`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                  10 Paquetes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                  20 Paquetes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                  50 Paquetes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                  100 Paquetes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={`text-muted d-lg-none mt-3 ${parcelList?.length === 0 || (isSelectedParcel && 'invisible')}`}>{`Página ${currentPage}`}</div>
          </Col>
        </Row>

        <div style={{ minHeight: '50vh' }} id="cardContainer">
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <Col xs="auto" className={`text-muted mb-3 ${parcelList?.length === 0 || (isSelectedParcel && 'invisible')}`}>
              {`Página ${currentPage}`}
            </Col>
          </Row>
          {/* List Header Start */}
          <Row className="g-0 h-100 align-content-center d-none d-lg-flex ps-5 pe-5 mb-2 custom-sort align-items-center">
            <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
              <div className="text-muted text-small cursor-pointer">NOMBRE</div>
            </Col>
            <Col lg="4" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">DESCRIPCION</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">LARGO</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">ANCHO</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">ALTO</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center">
              <div className="text-muted text-small cursor-pointer">KG</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-lg-end">
              <div className="text-muted text-small cursor-pointer text-end">VALOR CONTENIDO</div>
            </Col>
          </Row>
          {/* List Header End */}
          {parcelList?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center mt-5">
                Tu historial de paquetes está vacío.
                <br />
                ¿Quieres agregar un nuevo paquete?
              </h3>
              <Button
                href="/ajustes/paquetes/agregar"
                variant="outline-primary"
                className="btn-icon w-100 btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1  w-md-auto"
              >
                <CsLineIcons icon="plus" /> <span> Agregar </span>
              </Button>
            </div>
          ) : (
            <>
              {/* List Addresses Items Start */}
              {parcelList?.map((parcel) => (
                <CardParcel key={parcel.id} parcel={parcel} setIsModalOpen={setIsModalOpen} />
              ))}

              {/* List Addresses Items End */}
              {/* Address Modal Start */}
              <Modal className="modal-right" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header closeButton>
                  <Modal.Title as="h5">Editar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ModalParcel setIsModalOpen={setIsModalOpen} />
                </Modal.Body>
              </Modal>
              {/* Address Modal End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages === 0 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Parcels;
