import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button } from 'react-bootstrap';
// external libraries
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// utils
import { initialsUpperCase } from 'utils/textFormat';
// api
import { getAccountSTP, getCounters } from '../../api/Api-user';
// reducers
import { setDashboardData } from '../../slices/dashboardData/dashboardData';
// components
import CounterCard from './components/CounterCard';
import Loading from '../../components/loading/LoadingRed';
import ShipmentCard from './components/ShipmentCard';
import PickupCard from './components/PickupCard';

const Dashboard = () => {
  const title = 'Inicio';
  const description = 'Inicio Plataforma Autopaquete';
  const dispatch = useDispatch();
  const history = useHistory();
  // - Local States
  const [isLoading, setIsLoading] = useState(false);
  const [lastPickups, setLastPickups] = useState([]);

  // - Global State from Store.js form Values
  const { isLogin, currentUser } = useSelector((state) => state.auth);
  const { dashboardData } = useSelector((state) => state.init_data);
  // console.log('token desde el storage:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key_production);
  if (!isLogin) {
    history.push('/login');
    // history.goBack();
  }
  const dashboardCounters = [
    { icon: 'dollar', title: 'saldo actual', counter: dashboardData.fields.currentBalance },
    { icon: 'credit-card', title: 'clabe stp', counter: dashboardData.fields.stpAccount },
    { icon: 'shipping', title: 'envios', counter: dashboardData.fields.qtyShipments },
    { icon: 'destination', title: 'recolecciones', counter: dashboardData.fields.qtyPickups },
    // {icon:'online-class',title:'sesiones',counter:dashboardData.fields.qtySessions},
    // {icon:'code',title:'errores',counter: dashboardData.fields.qtyErr},
  ];

  useEffect(() => {
    // - MAKE REQUESTS TO GET THE COUNTERS
    const handlerGetLastShippings = () => {
      setIsLoading(true);
      getCounters('shippings?page=1&page_size=5').then((response) => {
        // console.log(response);
        if (response?.shippings) dispatch(setDashboardData({ point: 'lastShippings', data: response?.shippings }));
        setIsLoading(false);
      });
    };
    // - MAKE REQUESTS TO GET THE COUNTERS
    const handlerGetLastPickups = () => {
      setIsLoading(true);
      getCounters('pickups?page=1&page_size=5').then((response) => {
        // console.log(response);
        // last pickups in local states
        if (response?.pickups) setLastPickups(response?.pickups);
        // setIsLoading(false);
      });
    };
    const getDashboadCounters = async () => {
      try {
        const balance = await getCounters('/current-balance');
        const shippings = await getCounters('/shippings-counter');
        const pickups = await getCounters('/pickups-counter');
        const stp = await getAccountSTP(currentUser.crmId);
        // - SET COUNTERS GLOBAL STATES
        if (balance.current_balance) dispatch(setDashboardData({ point: 'currentBalance', data: balance.current_balance }));
        if (shippings.shippings_counter) dispatch(setDashboardData({ point: 'qtyShipments', data: shippings.shippings_counter }));
        if (pickups.pickups_counter) dispatch(setDashboardData({ point: 'qtyPickups', data: pickups.pickups_counter }));
        if (stp.response.clabe) dispatch(setDashboardData({ point: 'stpAccount', data: stp.response.clabe }));
      } catch {
        console.log('Error getting the counters');
      }
    };
    // - GET COUNTERS
    getDashboadCounters();
    // - GET LAST 5 SHIPPINGS
    handlerGetLastShippings();
    // - GET LAST 5 PICKUPS
    handlerGetLastPickups();
  }, [currentUser.crmId, dispatch]);
  // console.log('last pickup state:', lastPickups);
  // console.log('data dasboard:', dashboardData);
  // console.log('localstorage user:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth));
  // console.log('token user:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key_production);
  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
          <span className="align-middle text-small ms-1">&nbsp;</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4 text-center text-sm-start lh-base" id="title">
          {`¡Bienvenid@ ${initialsUpperCase(currentUser.name)}! ✨ `}
        </h1>
      </div>
      {/* Title End */}
      {/* Counter Cards Start */}
      <Row className="mb-5 g-2">
        {/* agregar condicional si no hay datos  */}
        {dashboardCounters.map((element) => (
          <CounterCard key={element.title} icon={element.icon} title={element.title} counter={element.counter} />
        ))}
      </Row>
      {/* Counter Cards End */}
      <Row>
        {/* Last Shippings Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Envíos Recientes</h2>
          <div style={{ minHeight: '350px' }}>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '350px' }}>
                <Loading />
              </div>
            ) : (
              dashboardData.fields.lastShippings.map((shipping) => <ShipmentCard key={shipping.id} shipping={shipping} />)
            )}
          </div>
        </Col>
        {/* Last Shippings End */}
        {/* Last Pickups Start */}
        <Col xl="6" className="mb-5">
          <h2 className="small-title">Recolecciones Recientes</h2>
          <div style={{ minHeight: '350px' }}>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: '350px' }}>
                <Loading />
              </div>
            ) : (
              lastPickups.map((pickup) => <PickupCard key={pickup.pickup_id} pickup={pickup} />)
            )}
          </div>
        </Col>
        {/* Last Pickups End */}
      </Row>
      <Row>
        {/* Tips 1 Start */}
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/manual.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Nuevo por aquí?</div>
                <div className="mb-3 cta-3 text-primary">Manual de usuario</div>
                <div className="text-alternate mb-4">Descubre la guía esencial para aprovechar al máximo las herramientas de nuestra plataforma. </div>
                {/* <div className="text-muted mb-4">Te invitamos a conocer como sacar el mayor provecho de las herramientas de esta plataforma.</div> */}
              </div>
              <Button
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1"
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://youtu.be/YSbmX0oNCUU');
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Ver manual</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {/* Tips 1 End */}
        {/* Tips 2 Start */}
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto bg-pink mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <div className="position-absolute w-100 bottom-0 end-0" style={{ height: '200px' }}>
              <img src="/img/autopaquete/enviar/recarga.webp" className="card-img h-100 cale" alt="card image" />
            </div>
            <Card.Body className="d-flex flex-column justify-content-between align-items-start bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Dudas con tu envío?</div>
                <div className="mb-3 cta-3 text-primary">Dudas frecuentes</div>
                <div className="text-alternate mb-4">Explora nuestra guía de preguntas frecuentes diseñada para resolver todas tus inquietudes.</div>
              </div>
              <Button href="/ayuda/soporte" variant="outline-primary" className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1">
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Click aquí</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {/* Tips 2 End */}
        {/* Tips 3 Start */}
        <Col xs="12" sm="6" xl="3">
          <Card
            className="position-relative h-100-card hover-img-scale-up mx-auto mb-3"
            style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}
          >
            <div className="position-absolute h-60 w-80 bottom-0 end-0">
              <img src="/img/autopaquete/enviar/soporte.webp" className="card-img h-100 cale" alt="card image" />
            </div>
            <Card.Body className="px-lg-4 d-flex flex-column justify-content-between align-items-start bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Problema con tu envío?</div>
                <div className="mb-3 cta-3 text-primary">Guía de incidencias</div>
                <div className="text-alternate mb-4">
                  Te proporcionamos información sobre las incidencias más frecuentes y cómo podemos ayudarte a resolverlas.
                </div>
              </div>
              <Button
                // href="/ajustes/direcciones/agregar"
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1 "
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://autopaqueteregistro.my.canva.site/soportelogisticoautopaquete');
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Ver manual</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {/* Tips 3 End */}
        {/* Tips 4 Start */}
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/reembolso.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Guía sin usar?</div>
                <div className="mb-3 cta-3 text-primary">Reembolso de guías</div>
                <div className="text-alternate mb-4">
                  Nuestro proceso de reembolso de guías está cuidadosamente diseñado para ofrecerte una experiencia sin complicaciones.
                </div>
              </div>
              <Button
                variant="outline-primary"
                className="btn-icon w-auto btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1"
                onClick={(e) => {
                  e.preventDefault();
                  window.open('https://zfrmz.com/Q8Rd1j4XfS8vnVpdCnBu');
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Click aquí</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        {/* Tips 4 End */}
      </Row>
    </>
  );
};

export default Dashboard;
