import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
// utils
import { initialsUpperCase } from 'utils/textFormat';

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const UserCard = ({ data }) => {
  // const counter = index + 1;
  //   const cutToTwoWords = (str) => {
  //     const words = str.split(' ');
  //     return words.slice(0, 2).join(' ');
  //   };

  return (
    <>
      <Card className="mb-2 py-4 py-lg-0 ps-5 pe-4 h-100 text-alternate">
        <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
          <Col xs="12" lg="1" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex  h-lg-100 justify-content-center order-3 order-lg-1">
            <div className="text-muted text-small d-lg-none">CRM ID</div>
            <div className="lh-1 text-alternate">{data.crm_user_id}</div>
          </Col>

          <Col lg="4" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-1 order-lg-2">
            <div className="text-muted text-small d-lg-none">Razón Social</div>
            <NavLink to={`/users-all/detail/${data?.crm_user_id}`}>{data.legal_name.toUpperCase()}</NavLink>
          </Col>

          <Col xs="6" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4 order-lg-3">
            <div className="text-muted text-small d-lg-none">Asesor</div>
            <div className="lh-1 text-alternate">{data.seller}</div>
          </Col>

          <Col lg="1" className=" d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-last order-lg-4">
            <div className="text-muted text-small d-lg-none">Tipo</div>
            <div className="lh-1 text-alternate">{data.customer_type}</div>
          </Col>
          <Col xs="6" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-5 order-lg-5">
            <div className="text-muted text-small d-lg-none">Status</div>
            <div className="lh-1 text-alternate">{data.is_active ? 'Activa' : 'Inactiva'}</div>
          </Col>

          <Col xs="11" lg="2" className=" d-flex flex-column pe-2 pe-sm-0 mb-2 mb-lg-0 align-items-start  order-3 order-lg-last justify-content-lg-center">
            <div className="text-muted text-small d-lg-none">Taifario</div>
            <div className="lh-1 text-alternate">{initialsUpperCase(data.rates_list)}</div>
          </Col>
          <Col xs="11" lg="1" className=" d-flex flex-column pe-2 pe-sm-0 mb-2 mb-lg-0 align-items-end  order-1 order-lg-last justify-content-lg-center">
            <div className="text-muted text-small d-lg-none">Saldo</div>
            <span className="text-alternative text-alternate">
              <span className="text-small text-muted ">$</span>
              {/* {fmDecimal.from(balance, { symbol: '$' })} */}
              {fmDecimal.from(data.current_balance)}
            </span>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default UserCard;
