import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
// api
import { getOverweights } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import OverweightRow from './components/OverweightRow';
import OverweightCard from './components/OverweightCard';

const LastOverweights = ({ crmId, userName }) => {
  const [overweights, setOverweights] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getOverweights(crmId, 1, 5)
      .then((response) => {
        console.log('Get overweights response', response);
        setIsLoading(false);
        setOverweights(response.overweights);
      })
      .catch(() => {
        // console.log('Err catch get invoices', err);
      });
  }, []);

  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {overweights?.length === 0 ? (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>No hay sobrepesos registrados.</h3>
        </Row>
      ) : (
        <>
          {overweights?.length > 0 && (
            <NavLink to={`/users-all/overweights/${crmId}/${userName}`} className="mb-3 cursor-pointer text-decoration-underline text-end">
              Ver todos
            </NavLink>
          )}
          {/* List Header Start */}
          {overweights?.length > 0 && (
            <OverweightRow />
            // <Row className="g-0 mb-2 d-none d-lg-flex">
            //   {/* <div className={`text-muted mb-3 ${overweights?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div> */}
            //   <Col xs="auto" className="sw-11 d-none d-lg-flex" />
            //   <Col>
            //     <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
            //       <Col xs="3" lg="4" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
            //         <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
            //       </Col>
            //       <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
            //         <div className="text-muted text-small cursor-pointer">FECHA CARGO</div>
            //       </Col>
            //       <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
            //         <div className="text-muted text-small cursor-pointer">KG COBRADOS</div>
            //       </Col>
            //       <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
            //         <div className="text-muted text-small cursor-pointer">KG REALES</div>
            //       </Col>
            //       <Col
            //         xs="2"
            //         lg="1"
            //         className="d-flex flex-column pe-1 justify-content mb-2 mb-lg-0 align-items-center order-2 order-lg-last justify-content-lg-center"
            //       >
            //         <div className="text-muted text-small cursor-pointer">CARGO</div>
            //       </Col>
            //     </Row>
            //   </Col>
            // </Row>
          )}
          {/* List Header End */}
          {/* List Items Start */}
          {overweights?.map((overweight, index) => (
            <OverweightCard data={overweight} key={index} />
          ))}
          {/* List Items End */}
        </>
      )}
    </>
  );
};

export default LastOverweights;
