import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import CardShipping from './components/CardShipping';
import Paginate from '../../../components/Paginate';
import ShippingCSV from '../../../components/reactCsv/ShippingCSV';
// api
import { getShippingHistory, getFilteredShippings } from '../../../api/Api-user';

const History = () => {
  const title = 'Historial';
  const description = 'Historial Plataforma Autopaquete';
  const { currentUser } = useSelector((state) => state.auth);
  // -Local States
  const [shipmentHistory, setShipmentHistory] = useState(null);
  const [idShipping, setIdShipping] = useState('');
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [isShippingById, setIsShippingById] = useState(false);
  const [isResetBtnActive, setIsResetBtnActive] = useState(false);

  // - Use references to do scroll Up
  const startRef = useRef(null);
  // - Screen switching between one or all shippings
  const cleanScreen = () => {
    setIdShipping(null);
    setIsLoading(false);
    setIsShippingById(false);
  };
  // - Get all shippings
  const handlerGetShippingHistory = async (page = 1, items = 10) => {
    try {
      const result = await getShippingHistory(page, items);
      // console.log('getShippingHistory result', result);
      // un error me llega como string result Error: Request failed with status code 404
      // en necesario que reciba solo result para poder manejar el .data que puede ser success o error
      // en data.eror no simpre viene como obj
      if (result.data.shippings !== undefined) {
        setShipmentHistory(result.data.shippings);
        setTotalPages(result.data.total_pages);
        setIsLoading(false);
        startRef.current.scrollIntoView({ behavior: 'smooth' });
        cleanScreen();
      }
      //  else if (result.data.error.shippings !== undefined) {
      //   toast.error(`Error: alguna guia no se encontro`, {
      //     // this is the err: "El envio con id 'null' no esta disponible.
      //     position: 'top-right',
      //     autoClose: 5000,
      //     hideProgressBar: true,
      //     closeOnClick: false,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'dark',
      //   });
      // }
    } catch (error) {
      // entra aqui cuando la peticion en api cae en el catch
      // o cuando hay un error y no es manejado el el try
      // console.log('error en catch history:', error.message);
      //  Cannot read properties of undefined (reading 'shippings')
      setIsLoading(false);
      setShipmentHistory(shipmentHistory); // para poder eliminar el loading del carrito que se setee con los datos anteriores
      // setCurrentPage(currentPage - 1); // para que mantenga el estado anterior tendria que quitarle uno o cambiar el componente paginacion

      toast.error('Error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  // - Search shipping by filters

  const handlerFilters = () => {
    setIsLoading(true);
    setIsResetBtnActive(true);
    getFilteredShippings(idShipping)
      .then((response) => {
        // console.log('response get shipping by id:', response);
        setIsShippingById(true);
        setShipmentHistory(response.shippings);
        setTotalPages(response.total_pages);
        setIsLoading(false);
        if (response.data.error.shippings !== undefined) {
          toast.error(`Error: ${response.data.error.shippings[0]}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        })
      .catch((err) => {
        // console.log('err get shipping by id:', err)
      });
  };

  // - Whenever the pagination status change
  useEffect(() => {
    handlerGetShippingHistory(currentPage, pageSize);
  }, [pageSize, currentPage]);
  // - If there is not shipping to look for
  useEffect(() => {
    if (idShipping?.length === 0) {
      handlerGetShippingHistory(currentPage, pageSize);
    }
  }, [idShipping]);
  const settingPage = (record) => {
    setIsLoading(true);
    setPageSize(record);
    setCurrentPage(1);
  };
  const resetInitialValues = () => {
    // setLabelId('');
    // setCrmId('');
    setIdShipping('');
    setIsLoading(true);
    setIsResetBtnActive(false);
    setCurrentPage(1);
    handlerGetShippingHistory();
  };
  return (
    <>
      {shipmentHistory === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      {/* pageContainer I use it to add opacity screen while loading data */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Excel Buttons Start */}
            <Col sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-3 order-sm-2">
              {isShippingById || (shipmentHistory?.length > 0 && <ShippingCSV shipmentHistory={shipmentHistory} crmId="" />)}
            </Col>
            {/* Excel  Buttons End */}
            {/* Top Buttons Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-2 order-sm-3">
              <NavLink to="/envios/enviar" className="btn btn-icon btn-outline-primary btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto">
                <CsLineIcons icon="plus" /> <span>Crear envío</span>
              </NavLink>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>
        {/* Dropdown and Search Buttons Start */}
        <Row className="g-0 mb-3">
          {/* Search Start */}
          <Col md="5" lg="3" xxl="2" className="mb-1">
            <div
              className={`d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground ${
                isShippingById || (shipmentHistory?.length === 0 && 'invisible')
              }`}
            >
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat' }}
                placeholder="Buscar por ID de envío"
                value={idShipping}
                onChange={(e) => {
                  setIdShipping(e.target.value);
                }}
              />
            </div>
          </Col>
          <Col xs="auto" md="auto" className="mb-2 ms-2">
            <Button variant="primary" style={{ height: '36px', width: 'auto' }} onClick={handlerFilters}>
              <span>Buscar</span>
            </Button>
            {isResetBtnActive && (
              <Button variant="outline-primary" style={{ height: '36px', width: 'auto', marginLeft: '4px' }} onClick={resetInitialValues}>
                <span>Regresar</span>
              </Button>
            )}
          </Col>
          {/* Search End md="7" lg="9" xxl="10" */}
          <Col xs="12" className="mb-1 text-end">
            <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${shipmentHistory?.length === 0 || (isShippingById && 'invisible')}`}>
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-15">
                {`${pageSize} Envios`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                  10 Envíos
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                  20 Envíos
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                  50 Envíos
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                  100 Envíos
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={`text-muted d-lg-none mt-3 ${shipmentHistory?.length === 0 || (isShippingById && 'invisible')}`}>{`Página ${currentPage}`}</div>
            {/* Length End */}
          </Col>
        </Row>
        {/* Dropdown and Search Buttons End */}
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          {/* List Header Start */}
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <div className={`text-muted mb-3 ${isShippingById || (shipmentHistory?.length === 0 && 'invisible')}`}>{`Página ${currentPage}`}</div>
            <Col xs="auto" className="sw-11 d-none d-lg-flex" />
            <Col>
              <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
                <Col xs="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                  <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
                </Col>
                <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">COSTO</div>
                </Col>
                <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center">
                  <div className="text-muted text-small cursor-pointer">PESO COBRADO</div>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* List Header End */}
          {/* List shipment Items End */}
          {shipmentHistory?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center mt-5">Tu historial de envíos estará disponible una vez que realices tu primer envío.</h3>
              <img alt="box" src="/img/animation/emptyBox.webp" style={{ width: '50px', height: '50px' }} />
            </div>
          ) : (
            <>
              {/* List shipment Items Start */}
              {shipmentHistory?.map((shipping) => (
                <CardShipping key={shipping.id} shipping={shipping} />
              ))}
              {/* List shipment Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 || (isShippingById && 'd-none')}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default History;
// onClick={(e) => nextPage(e.currentTarget)}
