import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Invoices from './components/Invoices';
import Receipts from './components/Receipts';
import Reps from './components/Reps';
import LoadingCar from '../../../components/loading/LoadingCar';

const Billing = () => {
  const title = 'Facturación';
  const description = 'Facturaión Plataforma Autopaquete';
  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [cardTypes, setCardTypes] = useState(<Invoices setIsLoadingStart={setIsLoadingStart} />);

  function submenuActive(e) {
    const submenuBtns = document.querySelectorAll('ul.nav-pills li button');
    const currentBtn = e.currentTarget.name;
    submenuBtns.forEach((btn) => {
      if (currentBtn === btn.name) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
  }
  const resetInfo = (e) => {
    const type = e.target.name;
    submenuActive(e);
    switch (type) {
      case 'invoice':
        setCardTypes(<Invoices setIsLoadingStart={setIsLoadingStart} />);
        break;
      case 'ref':
        setCardTypes(<Reps />);
        break;
      case 'receipts':
        setCardTypes(<Receipts />);
        break;
      default:
        setCardTypes(<Invoices />);
    }
  };

  return (
    <>
      {isLoadingStart && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </div>

        {/* Submenu horizontal */}
        <Row className="g-0 mb-5">
          <ul className="nav nav-pills">
            <li className="nav-item">
              <button type="button" className="nav-link active" name="invoice" onClick={(e) => resetInfo(e)}>
                Facturas
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className="nav-link" name="receipts" onClick={(e) => resetInfo(e)}>
                Recibos
              </button>
            </li>
            <li className="nav-item">
              <button type="button" className="nav-link" name="ref" onClick={(e) => resetInfo(e)}>
                REP'S
              </button>
            </li>
          </ul>
        </Row>
        <div>{cardTypes}</div>
      </div>
    </>
  );
};

export default Billing;
