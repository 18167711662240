import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  receiverData: {
    fields: {
      name: '',
      email: '',
      company: '',
      street: '',
      streetNumber: '',
      neighborhood: '',
      zip: '',
      city: '',
      state: { name: '', shipping_key: '' },
      placeRef: '',
      phone: '',
      addressType: 'RECEIVE',
    },
    errors: {
      name: false,
      street: false,
      streetNumber: false,
      neighborhood: false,
      zip: '',
      city: false,
      state: false,
      placeRef: false,
      phone: false,
    },
    valid: {
      name: false,
      street: false,
      streetNumber: false,
      neighborhood: false,
      zipFrom: false,
      city: false,
      state: false,
      placeRef: false,
      phone: false,
    },
  },
};

const receiverDataSlice = createSlice({
  name: 'receiverData',
  initialState,
  reducers: {
    setReceiverData(state, action) {
      const { point, data } = action.payload;
      state.receiverData.fields[point] = data;
    },
    setReceiverDataError(state, action) {
      const { point, data } = action.payload;
      state.receiverData.errors[point] = data;
    },
    setReceiverDataValidate(state, action) {
      const { point, data } = action.payload;
      state.receiverData.valid[point] = data;
    },
    clearReceiverForm: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});
export const { setReceiverData, setReceiverDataError, setReceiverDataValidate, clearReceiverForm } = receiverDataSlice.actions; // imported in enviar.js
const receiverDataReducer = receiverDataSlice.reducer; // store.js set up

export default receiverDataReducer;
