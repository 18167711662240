import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Modal } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Application, FileSelector } from 'react-rainbow-components';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// api
import { getOrders, getOrderExcel } from '../../../api/Api-user';
// components
import LoadingPulse from '../../../components/loading/LoadingPulse';
import LoadingCar from '../../../components/loading/LoadingCar';
import CardOrder from './components/CardOrder';
import Paginate from '../../../components/Paginate';

const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};
const Orders = () => {
  const title = 'Ordenes';
  const description = 'Ordenes Plataforma Autopaquete';
  // -Local States
  const [orders, setOrders] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  // Global states
  const { currentUser } = useSelector((state) => state.auth);
  // - Use references to do scroll Up
  const startRef = useRef(null);
  const token = `Bearer ${currentUser.api_key_production}`;

  const handlerGetOrders = async (page, items) => {
    try {
      const result = await getOrders(page, items);
      // console.log('result', result);
      setOrders(result.orders);
      setIsLoading(false);
      // setOrders([]);
      setTotalPages(result.total_pages);
      startRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      // console.log('error en catch statement history:', error);
    }
  };

  useEffect(() => {
    handlerGetOrders(currentPage, pageSize);
  }, [pageSize, currentPage]);

  const settingPage = (limit) => {
    setIsLoading(true);
    setCurrentPage(1);
    setPageSize(limit);
  };

  const handleCreateOrder = async () => {
    const formData = new FormData();
    formData.append('excel_file', file[0]);
    setIsModalOpen(false);

    axios
      .post('https://api.autopaquete.com.mx/v1/bulk_shippings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      })
      .then((response) => {
        // console.log('CREATE ORDER RES:', response);
        if (response.data.order_id) {
          toast.success(`La orden ha sido generada exitosamente con número de Id: ${response.data.order_id}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
        // REFRESH PAGE
        setTimeout(() => {
          handlerGetOrders(currentPage, pageSize);
        }, 2000);
      })
      .catch((err) => {
        console.error('Error:', err.response.data);
        toast.error(`Error: ${err.response.data.error} Código: ${err.response.data.error_code}`, {
          position: 'top-right',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      });
  };
  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };
  const downloadZip = () => {
    getOrderExcel('OTV1').then((response) => {
      // console.log('ORDER RES:', response);
      const blob = base64ToBlob(response.file, 'application/vnd.ms-excel');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Plantilla Ordenes.xlsx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
  console.log('Ordenes:', orders);
  // console.log('archivo excel:', file);
  return (
    <>
      {orders === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Excel Buttons Start */}
            <Col sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 me-2">
              <Button variant="primary" onClick={() => downloadZip()} style={{ height: '36px' }}>
                <CsLineIcons size="15" icon="download" className="me-1 mb-1" />
                <span>Plantilla Ordenes</span>
              </Button>
            </Col>
            {/* Excel  Buttons End */}
            {/* Top Buttons Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <Button
                className="me-2"
                variant="outline-primary"
                style={{ height: '36px' }}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <CsLineIcons icon="plus" size="15" className="mb-1" /> <span>Crear orden</span>
              </Button>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Crear orden de envíos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Application theme={theme}>
              <FileSelector
                className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto mx-auto"
                // style={containerStyles}
                label="Subir archivo excel"
                placeholder="Sube o arrastra tu archivo aquí"
                bottomHelpText="Selecciona solo un archivo"
                onChange={setFile}
                borderRadius="semi-rounded"
              />
            </Application>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setIsModalOpen(false)}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleCreateOrder}>
              Continuar
            </Button>
          </Modal.Footer>
        </Modal>
        <Row className="mb-3">
          <Col md="5" lg="3" xxl="2" />
          <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
            <Dropdown align={{ xs: 'end' }} className="d-inline-block ms-1">
              <Dropdown.Toggle variant="foreground-alternate" className={`shadow sw-20 ${orders?.length === 0 && 'invisible'}`}>
                {`${pageSize} Ordenes`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                  10 Ordenes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                  20 Ordenes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                  50 Ordenes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                  100 Ordenes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={`text-muted d-lg-none mt-3 ${orders?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div>
          </Col>
        </Row>

        <div style={{ minHeight: '350px' }} id="cardContainer">
          {/* List Header Start */}
          <Row className="g-0 mb-2 d-none d-lg-flex">
            <Row className="g-0 mb-2 d-none d-lg-flex">
              <Col xs="auto" className={`text-muted mb-3 ${orders?.length === 0 && 'invisible'}`}>
                {`Página ${currentPage}`}
              </Col>
              {/* <div className={`text-muted mb-3 ${orders?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div>  */}
            </Row>
            {/* <Col xs="auto" className="sw-11 d-none d-lg-flex" /> */}
            {/* <Col> */}
            <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-5 h-100">
              <Col xs="3" lg="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                <div className="text-muted text-small cursor-pointer">ID ORDEN</div>
              </Col>
              <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
              </Col>
              <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">ENVÍOS</div>
              </Col>
              <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">CREADOS</div>
              </Col>
              <Col xs="2" lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">PENDIENTES</div>
              </Col>
              <Col
                xs="2"
                lg="1"
                className="d-flex flex-column pe-1 justify-content mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center"
              >
                <div className="text-muted text-small cursor-pointer">ERRORES</div>
              </Col>
            </Row>
            {/* </Col> */}
          </Row>
          {/* List Header End */}
          {orders?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center mt-5">Aún no tienes historial de órdenes.</h3>
              {/* . Si deseas realizar una, contacta a un asesor para que te explique cómo hacerlo. */}
              {/* <img alt="box" src="/img/animation/emptyBox.webp" style={{ width: '50px', height: '50px' }} /> */}
            </div>
          ) : (
            <>
              {/* List Items Start */}
              {orders?.map((order) => (
                <CardOrder key={order.id} order={order} />
              ))}
              {/* List Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages === 0 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setCurrentPage} setIsLoading={setIsLoading} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Orders;
