import { createSlice } from '@reduxjs/toolkit';
// https://docs.autopaquete.com.mx/

const initialState = {
  senderData: {
    fields: {
      name: '',
      email: '',
      company: '',
      street: '',
      streetNumber: '',
      neighborhood: '',
      zip: '',
      city: '',
      state: { name: '', shipping_key: '' },
      placeRef: '',
      phone: '',
      addressType: 'SEND',
    },
    errors: {
      name: false,
      street: false,
      streetNumber: false,
      neighborhood: false,
      zipFrom: '',
      city: false,
      state: false,
      placeRef: false,
      phone: false,
    },
    valid: {
      name: false,
      street: false,
      streetNumber: false,
      neighborhood: false,
      zipFrom: false,
      city: false,
      state: false,
      placeRef: false,
      phone: false,
    },
  },
};

const senderDataSlice = createSlice({
  name: 'senderData',
  initialState,
  reducers: {
    setSenderData(state, action) {
      const { point, data } = action.payload;
      state.senderData.fields[point] = data;
    },
    setSenderDataError(state, action) {
      const { point, data } = action.payload;
      state.senderData.errors[point] = data;
    },
    setSenderDataValidate(state, action) {
      const { point, data } = action.payload;
      state.senderData.valid[point] = data;
    },
    clearSenderForm: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});
export const { setSenderData, setSenderDataError, setSenderDataValidate, clearSenderForm } = senderDataSlice.actions; // imported in enviar.js and NavIconMenu.js
const senderDataReducer = senderDataSlice.reducer; // store.js set up

export default senderDataReducer;
