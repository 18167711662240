import React from 'react';
import { Row, Col } from 'react-bootstrap';

const OverweightRow = () => {
  return (
    <Row className="g-0 mb-2 d-none d-lg-flex">
      {/* <div className={`text-muted mb-3 ${overweights?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div> */}
      <Col xs="auto" className="sw-11 d-none d-lg-flex" />
      <Col>
        <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
          <Col xs="3" lg="4" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
            <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
          </Col>
          <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center align-items-center">
            <div className="text-muted text-small cursor-pointer">FECHA CARGO</div>
          </Col>
          <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
            <div className="text-muted text-small cursor-pointer">KG COBRADOS</div>
          </Col>
          <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-lg-center">
            <div className="text-muted text-small cursor-pointer">KG REALES</div>
          </Col>
          <Col
            xs="2"
            lg="1"
            className="d-flex flex-column pe-1 justify-content mb-2 mb-lg-0 align-items-center order-2 order-lg-last justify-content-lg-center"
          >
            <div className="text-muted text-small cursor-pointer">CARGO</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default OverweightRow;
