import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const Loading = () => {
  return (
    // color="#999999"
    <div>
      <BeatLoader color="var(--primary-text)" size="13px" />
    </div>
  );
};
export default Loading;
