import React, { memo } from 'react';
import { Route, Redirect } from 'react-router-dom';

const RouteItem = ({ redirect = false, exact = false, path, to, component}) => {
  // const userRoles = ['user'];
  // console.log('redirect:', redirect, exact, path, to, component, roles);
  // console.log('exact:', exact);
  // console.log('path:', path);
  // console.log('to:', to);
  // console.log('component:', component);
  // console.log('roles RouteItem:', roles);
  // const hasAccess = roles.length === 0 || roles.some((role) => userRoles.includes(role));
  // console.log('la ruta:', path, 'tiene permisos', hasAccess);
  if (!path && !to) {
    return () => {};
  }
  if (redirect) {
    const props = {};
    if (path) props.from = path;
    if (exact) props.exact = exact;
    if (to) props.to = to;
    return <Redirect {...props} />;
  }
  // if (!hasAccess) {
  //   return <Redirect to="/page-not-found" />;
  // }
  const props = {};
  if (path) props.path = path;
  if (exact) props.exact = exact;
  if (component) props.component = component;

  return <Route {...props} />;
};

export default memo(RouteItem);
