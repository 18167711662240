import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Row } from 'react-bootstrap';

// api
import { getAdminShippings } from '../../../../../api/Api-admin';
// components
import Loading from '../../../../../../components/loading/LoadingRed';
import ShippingRow from './components/ShippingRow';
import ShippingCard from './components/ShippingCard';
 
const Shippings = ({ crmId, userName }) => {
  const [shippings, setShippings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const currentPage = 1;
  const pageSize = 5;

  useEffect(() => {
    getAdminShippings(currentPage, pageSize , crmId, '', '', '')
      .then((response) => {
        console.log('Get last 5 shippings by user response', response);
        setIsLoading(false);
        setShippings(response.shippings);
      })
      .catch(() => {
        // console.log('Err catch get shippings', err);
      });
  }, []);

  // console.log(shippings)
  return (
    <>
      {isLoading && (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <Loading />
        </Row>
      )}
      {shippings?.length === 0 ? (
        <Row className="g-0 sh-8 my-4 d-flex align-items-center text-center">
          <h3>No hay historial de envíos.</h3>
        </Row>
      ) : (
        <>
          {/* List Header Start */}
          {shippings?.length > 0 && (
            <NavLink to={`/users-all/shippings/${crmId}/${userName}`} className="mb-3 cursor-pointer text-decoration-underline text-end">
              Ver todos
            </NavLink>
          )}
          {shippings?.length > 0 && <ShippingRow />}
          {/* List Header End */}
          {/* List Items Start */}
          {shippings?.map((shipping, index) => (
            <ShippingCard data={shipping} key={index} />
          ))}
          {/* List Items End */}
        </>
      )}
    </>
  );
};

export default Shippings;
