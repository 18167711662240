import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';

const AdminLoader = () => {
  return (
    <div className="p-5 position-fixed top-0 bottom-0 start-0 end-0" style={{ backgroundColor: 'var(--background)' }}>
      <div className="position-fixed top-50 start-50 translate-middle-x translate-middle-y">
        <PacmanLoader color="var(--primary-text)" size="15px" />
      </div>
    </div>
  );
};
export default AdminLoader;
