import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ratesData: {},
};

const ratesDataSlice = createSlice({
  name: 'ratesData',
  initialState,
  reducers: {
    setRatesData(state, action) {
      state.ratesData = action.payload;
    },
  },
});

export const { setRatesData } = ratesDataSlice.actions; // imported in ----.js
const ratesDataReducer = ratesDataSlice.reducer; // store.js set up

export default ratesDataReducer;
