import React, { useEffect } from 'react';
import { Spinner, Application } from 'react-rainbow-components';

const theme = {
  rainbow: {
    palette: {
      brand: '#850000',
    },
  },
};

const LoadingCar = () => {
  useEffect(() => {
    const contentArea = document.querySelector('#pageContainer');
    // console.log('content area:', contentArea);
    const htmlTag = document.documentElement;
    if (!contentArea) {
      htmlTag.setAttribute('data-show', 'false');
    } else {
      contentArea.style.opacity = '0';
      // contentArea.style.backgroundColor = 'var(--background)';
    }
    document.body.classList.add('spinnerr');

    return () => {
      setTimeout(() => {
        if (!contentArea) {
          htmlTag.setAttribute('data-show', 'true');
        } else {
          contentArea.style = 'opacity:1';
        }
        document.body.classList.remove('spinnerr');
      }, 30);
    };
  }, []);
  return (
    <Application theme={theme}>
      <Spinner size="large" type="arc" variant="brand" className="position-fixed top-50">
        <img alt="autopaquete" src="/img/logo/logo-car-red.png" width="30px" height="30px" />
      </Spinner>
    </Application>
    // <div className="rainbow-p-vertical_xx-large ">
    //   <div className="rainbow-position_relative rainbow-p-vertical_xx-large bg-secondary">
    //     <Spinner size="large" type="arc" variant="brand" className="bg-info  position-fixed top-50">
    //       <img alt="autopaquete" src="/img/logo/logo-autocarrito.png" width="30px" height="30px" />
    //     </Spinner>
    //   </div>
    // </div>
  );
};
export default LoadingCar;
