import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addOverweight, addAdditionalCharge, getChargeConcepts } from '../../../api/Api-admin';

const ShippingModal = ({ data, isModalOpen, setIsModalOpen, title, type }) => {
  const labelId = data.shipping_label_id;
  const crmId = data.crm_user_id;

  const [chargeConceptList, setChargeConceptList] = useState([]);
  const [nameConcept, setNameConcept] = useState('Concepto');
  const [codeConcept, setCodeConcept] = useState('');
  const [overweight, setOverweight] = useState('');
  const [chargeAmount, setChargeAmount] = useState('');
  const [error, setError] = useState('');

  const updateField = (value) => {
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      if (type === 'ow') {
        setOverweight(value);
      } else if (type === 'ad') {
        setChargeAmount(value);
      }
    }
    setError('');
  };
  const closeModal = () => {
    setOverweight('');
    setChargeAmount('');
    setNameConcept('Concepto');
    setCodeConcept('');
    setIsModalOpen(false);
  };
  const handlerAddOverweight = () => {
    if (overweight) {
      // console.log(`agregar sobrpesos de ${overweight} kg, en la guai numeror: ${labelId}`);
      addOverweight(data.shipping_label_id, overweight)
        .then((response) => {
          console.log('add overweight res:', response);
          if (response.overweight_charge !== undefined) {
            toast.success(
              `Se agrego sobrepeso correctamente ⭐
               ${response.client_name}
             / ID Envío: ${response.shipping_id}
             / Cargo: $${response.overweight_charge}`,
              {
                position: 'top-right',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              }
            );
            closeModal();
          }
          if (response.data.error.overweights[0] !== undefined) {
            setError(`Error: ${response.data.error.overweights[0]}`);
          }
        })
        .catch((err) => console.log('add overweight catch err:', err));
    } else {
      toast.error('Error: Diferencia de kilos es requerido', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  const handlerAddCharges = () => {
    if (chargeAmount && codeConcept) {
      addAdditionalCharge(crmId, labelId, chargeAmount, codeConcept).then((response) => {
        console.log('agregar cargo res:', response);
        if (response.client_id !== undefined) {
          toast.success(`Se aplico cargo correctamente ⭐`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          closeModal();
        }
      });
    } else {
      toast.error('Error: Concepto y monto son requeridos', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  useEffect(() => {
    // REDUCES_BALANCE   INCREASES_BALANCE
    if (type === 'ad') {
      getChargeConcepts('REDUCES_BALANCE').then((response) => {
        setChargeConceptList(response.list);
      });
    }
  }, [type]);
  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column flex-sm-row">
          <span className="w-sm-50">
            <p className="text-small text-muted mb-2">ENVIO</p>
            <div className="d-flex align-items-center lh-1-25">Usuario</div>
            <div className="text-primary">{data.razon_social.toUpperCase()}</div>
            <div className="d-flex align-items-center lh-1-25 ">ID</div>
            <div className="text-primary">{labelId}</div>
            <div className="d-flex align-items-center lh-1-25 ">Servicio</div>
            <div className="text-primary">{data.shipping_code.replace(/_+/g, ' ')}</div>
          </span>
          <span className="w-sm-50 ps-sm-4">
            <p className="text-small text-muted mb-2 pt-4 pt-sm-0">PAQUETE</p>
            <div className="d-flex align-items-center lh-1-25">Medidas</div>
            <div className="text-primary">{`${data.parcel?.depth} x ${data.parcel?.width} x ${data.parcel?.height} `}</div>
            <div className="d-flex align-items-center lh-1-25 mt-sm-4">Peso declarado</div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-2"> Físico:</span>
              {`${data.parcel.weight} kg`}
            </div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-3"> Volumétrico:</span>
              {`${data.parcel.volumetric_weight} kg`}
            </div>
            <div className="text-primary">
              <span className="text-medium text-alternate pe-3"> Final:</span>
              {`${data.parcel.total_weight} kg`}
            </div>
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-center align-items-sm-end">
        <div className="d-flex flex-column flex-sm-row align-items-center align-items-sm-end">
          {type === 'ad' && (
            <>
              <Dropdown className="d-inline-block me-2">
                <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-auto" style={{ minWidth: '246px' }}>
                  {nameConcept}
                </Dropdown.Toggle>
                <Dropdown.Menu className="shadow " style={{ maxHeight: '250px', overflowY: 'scroll', fontFamily: 'Montserrat' }}>
                  {chargeConceptList.length > 0 &&
                    chargeConceptList.map((element, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setNameConcept(element.name_concept);
                          setCodeConcept(element.code_concept);
                        }}
                      >
                        {element.name_concept}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat' }}
                className="nav-shadow me-2 w-90 my-3 my-sm-0"
                placeholder="$0"
                value={chargeAmount}
                onChange={(e) => {
                  // setChargeAmount(e.target.value); , width: '100px' , width: '120px'
                  updateField(e.target.value);
                }}
              />
            </>
          )}
          {type === 'ow' && (
            <div className="me-sm-2 d-flex flex-column align-items-sm-end">
              {/* <Form.Label className="ms-2">Diferencia</Form.Label> */}
              <Form.Control
                type="text"
                style={{ fontFamily: 'Montserrat' }}
                className="nav-shadow w-90 w-sm-70 mb-3 ms-2 mb-sm-0"
                placeholder="Diferencia kg"
                value={overweight}
                onChange={(e) => updateField(e.target.value)}
              />
            </div>
          )}
          <Button variant="primary" className="w-90 w-sm-auto" onClick={type === 'ow' ? handlerAddOverweight : handlerAddCharges}>
            Aplicar
          </Button>
        </div>

        <div className="text-primary text-medium">{error}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShippingModal;
