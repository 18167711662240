import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ShippingRow = () => {
  return (
    <Row className="g-0 mb-2 d-none d-lg-flex">
      {/* <div className={`text-muted mb-3 ${isShippingById || (shipmentHistory?.length === 0 && 'invisible')}`}>{`Página ${currentPage}`}</div> */}
      <Col xs="auto" className="sw-11 d-none d-lg-flex" />
      <Col>
        <Row className="g-0 h-100 align-content-center custom-sort px-4 h-100">
          <Col lg="3" className="d-flex flex-column mb-lg-0 pe-3">
            <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
          </Col>
          <Col lg="3" className="d-flex flex-column pe-1 justify-content-center align-items-center">
            <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
          </Col>
          <Col lg="3" className="d-flex flex-column pe-1 justify-content-center align-items-center">
            <div className="text-muted text-small cursor-pointer">COSTO</div>
          </Col>
          <Col lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-center">
            <div className="text-muted text-small cursor-pointer">PESO COBRADO</div>
          </Col>
          <Col lg="1" className="d-flex flex-column pe-1 justify-content-center invisible">
            <div className="text-muted text-small cursor-pointer">PDF</div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default ShippingRow;
