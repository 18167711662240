import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addOverweight } from '../../../api/Api-admin';

const OverweightModal = ({ isModalOpen, setIsModalOpen, setUpdatePage }) => {
  const [overweight, setOverweight] = useState('');
  const [labelId, setLabelId] = useState('');
  const [error, setError] = useState('');

  const updateField = (value) => {
    if (value === '' || /^[0-9]+$/i.test(value)) {
      setOverweight(value);
    }
    setError('');
  };
  const closeModal = () => {
    setOverweight('');
    setLabelId('');
    setError('');
    setIsModalOpen(false);
  };
  const closeModalAndRenderData = () => {
    closeModal();
    setUpdatePage(true);
  };
  const handlerAddOverweight = () => {
    if (overweight) {
      // console.log(`agregar sobrpesos de ${overweight} kg, en la guai numeror: ${labelId}`);
      addOverweight(labelId, overweight)
        .then((response) => {
          console.log('add overweight res:', response);
          if (response.overweight_charge !== undefined) {
            toast.success('Se agrego sobrepeso correctamente ⭐', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
            closeModalAndRenderData();
          }
          if (response.data.error.overweights[0] !== undefined) {
            setError(`Error: ${response.data.error.overweights[0]}`);
          }
        })
        .catch((err) => console.log('add overweight catch err:', err));
    } else {
      setError('Error: Todos los campos son requeridos');
      setTimeout(() => setError(''), 5000);
    }
  };
  // console.log('estado overweight:', overweight);

  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>Sobrepeso</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Form.Control
          type="text"
          style={{ fontFamily: 'Montserrat' }}
          className="nav-shadow me-2 w-50 my-3 my-sm-0"
          placeholder="ID Envío"
          value={labelId}
          onChange={(e) => {
            setLabelId(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-center align-items-sm-end">
        <div className="d-flex justify-content-center justify-content-sm-end">
          <Form.Control
            type="text"
            style={{ fontFamily: 'Montserrat' }}
            className="nav-shadow w-50 me-2"
            placeholder="Diferencia kg"
            value={overweight}
            onChange={(e) => updateField(e.target.value)}
          />

          <Button variant="primary" className="w-sm-auto" onClick={handlerAddOverweight}>
            Aplicar
          </Button>
        </div>
        <div className="text-primary text-medium">{error}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default OverweightModal;
