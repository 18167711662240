import React from 'react';
import { Row, Col } from 'react-bootstrap';

const StatementRow = () => {
  return (
    <Row className="g-0 h-100 align-content-center d-none d-lg-flex px-4 mb-2 custom-sort">
      <Col md="3" className="d-flex flex-column mb-lg-0 pe-3">
        <div className="text-muted text-small cursor-pointer">CONCEPTO</div>
      </Col>
      <Col md="2" className="d-flex flex-column pe-1 justify-content-center">
        <div className="text-muted text-small cursor-pointer">FECHA MOVIMIENTO</div>
      </Col>
      <Col md="3" className="d-flex flex-column pe-1 justify-content-center">
        <div className="text-muted text-small cursor-pointeR">REFERENCIA</div>
      </Col>
      <Col md="2" className="invisible">
        -
      </Col>
      <Col md="1" className="d-flex flex-column pe-1 justify-content-center align-items-center">
        <div className="text-muted text-small cursor-pointer">MONTO</div>
      </Col>
      <Col md="1" className="d-flex flex-column pe-1 justify-content-center text-end">
        <div className="text-muted text-small cursor-pointer">SALDO</div>
      </Col>
    </Row>
  );
};

export default StatementRow;
