import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Card, Col } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Accordion, AccordionSection } from 'react-rainbow-components';

const Support = () => {
  const title = 'Soporte Logístico';
  const description = 'Soporte Logístico Plataforma Autopaquete';
  const { currentUser } = useSelector((state) => state.auth);


  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Inicio</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End   style={{backgroundColor:"salmon", height:"500px"}} */}

      <Card className="mb-5">
        <Card.Body>
          <Col xs="12" xxl="10">
            <div className="responsive-text mb-5">
              Autopaquete es el encargado de realizar cualquier tipo de reporte con respecto a las guías creadas a través de esta plataforma. Ningún tercero
              podrá realizar el seguimiento directamente, y en caso de ser asi, no podremos hacernos responsables de ese envío. <br />
              <br />
              Te invitamos a revisar nuestro manual de procesos para una rápida solución ante cualquier incidencia.
              <span className="ps-2">
                <a target="_blank" rel="noopener noreferrer" href="https://autopaqueteregistro.my.canva.site/soportelogisticoautopaquete">
                  Ver Manual Logístico
                </a>
              </span>
            </div>
            <Accordion id="accordion-1" className="accordion">
              {/* <AccordionSection label="Recolecciones">
                Te compartimos los detalles de cómo puedes solicitar la recolección, para cada paquetería.
                <div className="d-flex flex-wrap mt-3">
                  <a
                    className="me-4 mb-4 mb-sm-2"
                    href=""
                    onClick={() =>
                      window.open(`https://wa.me/+523323107624/?text=${`Hola soy ... con número de cuenta ..., Quiero programar una recolección de fedex`}`)
                    }
                  >
                    Programar recolección de FedEx y J&T Express
                  </a>
                  <a 
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://youtu.be/MxNGGN3-84E"
                  >Otras paqueterías</a>
                </div>
              </AccordionSection> */}
              <AccordionSection label="Sobrepesos">
                ¿Tienes algún descuento aplicado por diferencia en las medidas y peso de tu paquete? Es posible realizar la aclaración.
                <br />
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/IyRVQ43hgBJu2op4LV3z">
                  Pedir aclaración
                </a>
              </AccordionSection>
              <AccordionSection label="Reembolsos">
                No utilizaste alguna guía; envíanos la información. A más tardar, en 15 días hábiles, tendrás tu dinero de regreso.
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/Q8Rd1j4XfS8vnVpdCnBu ">
                  Solicitar servicio
                </a>
              </AccordionSection>
              <AccordionSection label="Agilizar Entrega">
                Solicita este tipo de apoyo cuando haya retraso en la entrega (más de 7 días hábiles en tránsito a su destino), falten datos en la dirección
                destino, el destinatario no esté en el domicilio y se necesite que se envíe de nuevo, el destinatario decida recogerlo en una sucursal o,
                viceversa, necesite que desde una sucursal sea enviado al domicilio de la dirección, o incluso cuando sea rechazado erróneamente.
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/XkuCZOR5fzoJjEjZTU40">
                  Solicitar apoyo
                </a>
              </AccordionSection>
              <AccordionSection label="Cambio de Domicilio">
                En caso de que se haya tenido algún error en la dirección al momento del llenado de la guía. *No aplica para FedEx y Estafeta.
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/RAhJpKnoPfgHm60dOuhd">
                  Solicitar corrección
                </a>
              </AccordionSection>
              <AccordionSection label="Entrega Errónea">
                La entrega se ha realizado en una dirección diferente a la de la guía; la evidencia de entrega no coincide con la información del destinatario.
                Infórmanos de inmediato. La alerta debe realizarse antes de que cumplan las 24 horas desde su recepción.
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/jkMBbAhR5WJOm6JIwNPh">
                  Reportarlo
                </a>
              </AccordionSection>
              <AccordionSection label="Paquete Dañado">
                El paquete ha sido recibido, pero el contenido se encuentra roto o en mal estado. ¡Repórtalo con nosotros de inmediato! La alerta debe
                realizarse antes de que cumplan las 24 horas desde su recepción.
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/bHJOU6CDBJtrEAaSzHG5">
                  Reportar daño en contenido
                </a>
              </AccordionSection>
              <AccordionSection label="Paquete Incompleto">
                Se ha recibido el paquete y faltan piezas? Recaba la evidencia necesaria para el reporte. Nosotros te informamos exactamente lo que se requiere.
                La alerta debe realizarse antes de que cumplan las 24 horas desde su recepción.
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href=" https://zfrmz.com/YnVf1HSTdcfrRaRb6r82">
                  Informar sobre faltante
                </a>
              </AccordionSection>
              <AccordionSection label="Paquete Perdido">
                Ha transcurrido más tiempo de lo habitual; la paquetería no actualiza sus estados y no se sabe nada sobre el paquete. Comunícate con nuestra
                área de Soporte Logístico para que te ayudemos.
                <br />
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/bw61fOzNDIJU5ri7em35">
                  Solicitar apoyo
                </a>
              </AccordionSection>
              <AccordionSection label="Retorno de Paquete">
                Necesitas que el paquete no sea entregado y que sea devuelto al domicilio de origen. El destino ha rechazado la entrega y debe ser devuelto al
                remitente.
                <br />
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/rzt38osKx0CkDQmwK8Ce">
                  Retornar paquete
                </a>
              </AccordionSection>
              <AccordionSection label="Robo a Ruta">
                La paquetería ha reportado un robo a su unidad o un accidente mayor. Si tu paquete está asegurado, es posible solicitar la indemnización. Te
                informamos sobre lo que se requiere y te ayudamos.
                <br />
                <a target="_blank" rel="noopener noreferrer" className="d-block mt-3" href="https://zfrmz.com/bw61fOzNDIJU5ri7em35">
                  Solicitar asistencia
                </a>
              </AccordionSection>
              <AccordionSection label="Productos Prohibidos">
                <p>
                  Conoce qué productos están prohibidos por ley para enviarse a través de las paqueterías y que no participan en la indemnización en caso de
                  incidencia.
                </p>
                <ul>
                  <li> Animales o plantas</li>
                  <li> Armas</li>
                  <li> Bebidas alcohólicas</li>
                  <li> Cigarros electrónicos</li>
                  <li> Documentos de valor</li>
                  <li> Estupefacientes</li>
                  <li> Explosivos</li>
                  <li> Inflamables</li>
                  <li> Joyas, dinero u objetos de valor</li>
                  <li> Magneticos o radioactivos</li>
                  <li> Medicamentos</li>
                  <li> Mercancia sin documentación</li>
                  <li> Muestras de laborarorios</li>
                  <li> Organos o cuerpos</li>
                  <li> Oxidantes o solventes</li>
                  <li> Piel o cuero de animales</li>
                  <li> Piratería o pronografía</li>
                  <li> Punzo cortantes</li>
                  <li> Semillas</li>
                  <li> Tintas (serigrafia)</li>
                  <li> Vidrio</li>
                </ul>
                <p>
                  Para más detalles visita:
                  <div className="d-flex flex-wrap mt-3">
                    <span>
                      <a target="_blank" rel="noopener noreferrer" className="pe-3" href="https://www.fedex.com/es-mx/shipping/prohibited-items.html">
                        Productos prohibidos fedex
                      </a>
                    </span>
                    <span>
                      <a target="_blank" rel="noopener noreferrer" className="pe-3" href="https://www.estafeta.com/Atencion-al-Cliente/Articulos-prohibidos">
                        Productos prohibidos estafeta
                      </a>
                    </span>
                    <span>
                      <a target="_blank" rel="noopener noreferrer" className="pe-3" href="https://www.redpack.com.mx/es/articulos-prohibidos/">
                        Productos prohibidos redpack
                      </a>
                    </span>
                  </div>
                </p>
              </AccordionSection>
              <AccordionSection label="Atención y servicio">
                ¿Tienes alguna sugerencia sobre nuestros servicios y atención? Comunícate con nuestra área de calidad y con gusto te atenderemos.
                <br />
                <Button
                  className="d-block mt-3"
                  variant="link"
                  onClick={() => window.open(`https://wa.me/+523319774057/?text=${`Hola Paulina soy ${currentUser.name}/ ${currentUser.crmId}, ¿Podrías ayudarme?`}`)}
                >
                  Asistencia al cliente
                </Button>
              </AccordionSection>
            </Accordion>
          </Col>
        </Card.Body>
      </Card>
    </>
  );
};

export default Support;
