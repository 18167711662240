import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addIncreaseBalance, addAdditionalCharge, getChargeConcepts } from '../../../api/Api-admin';

const BalanceModal = ({ isModalOpen, setIsModalOpen, title, type, crmId, setUpdatePage }) => {
  const [chargeConceptList, setChargeConceptList] = useState([]);
  const [nameConcept, setNameConcept] = useState('Concepto');
  const [codeConcept, setCodeConcept] = useState('');
  const [reference, setReference] = useState('');
  const [labelId, setLabelId] = useState('');
  const [chargeAmount, setChargeAmount] = useState('');
  const [error, setError] = useState('');
  const updateField = (value) => {
    if (value === '' || /^\d*\.?\d*$/.test(value)) {
      setChargeAmount(value);
    }
    setError('');
  };
  const closeModal = () => {
    setChargeAmount('');
    setNameConcept('Concepto');
    setCodeConcept('');
    setLabelId('');
    setIsModalOpen(false);
  };
  const closeModalAndRenderData = () => {
    closeModal();
    setUpdatePage(true);
  };
  const handlerIncreaseBalance = () => {
    if (chargeAmount && codeConcept && reference) {
      addIncreaseBalance(chargeAmount, codeConcept, crmId, reference).then((response) => {
        // console.log('agregar saldo res:', response);
        if (response.client_id !== undefined) {
          toast.success(`Se agrego saldo correctamente ⭐`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          closeModalAndRenderData();
        }
      });
    } else {
      setError('Error: Todos los campos son requeridos');
      setTimeout(() => setError(''), 5000);
    }
  };
  const handlerAddCharges = () => {
    if (chargeAmount && codeConcept && labelId) {
      addAdditionalCharge(crmId, labelId, chargeAmount, codeConcept).then((response) => {
        console.log('cargo adicional res:', response);
        if (response.client_id !== undefined) {
          toast.success(`Se aplico cargo correctamente ⭐`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          closeModalAndRenderData();
        }
      });
    } else {
      setError('Error: Todos los campos son requeridos');
      setTimeout(() => setError(''), 5000);
    }
  };
  useEffect(() => {
    // REDUCES_BALANCE   INCREASES_BALANCE
    if (type === 'increase') {
      getChargeConcepts('INCREASES_BALANCE').then((response) => {
        // console.log('concepts res:', response);
        setChargeConceptList(response.list);
      });
    } else if (type === 'reduce') {
      getChargeConcepts('REDUCES_BALANCE').then((response) => {
        // console.log('concepts res:', response);
        setChargeConceptList(response.list);
      });
    }
  }, [type]);
  // console.log('concept:', nameConcept);
  // console.log('concept code:', codeConcept);  en la guia numero: ${labelId}
  // console.log('cargo adicional data:', crmId, labelId);

  return (
    <Modal show={isModalOpen} onHide={() => closeModal()}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <>
          {type === 'increase' ? (
            <Form.Control
              type="text"
              style={{ fontFamily: 'Montserrat' }}
              className="nav-shadow me-2 w-50 my-3 my-sm-0"
              placeholder="Referencia"
              value={reference}
              onChange={(e) => {
                setReference(e.target.value);
              }}
            />
          ) : (
            <Form.Control
              type="text"
              style={{ fontFamily: 'Montserrat' }}
              className="nav-shadow me-2 w-50 my-3 my-sm-0"
              placeholder={`${nameConcept !== 'Retorno Guia' ? 'ID Envío' : 'Referencia'}`}
              value={labelId}
              onChange={(e) => {
                setLabelId(e.target.value);
              }}
            />
          )}
        </>
      </Modal.Body>
      <Modal.Footer className="d-flex flex-column align-items-center align-items-sm-end py-3 ps-0">
        <div className="d-flex flex-column flex-sm-row align-items-center align-items-sm-end ">
          <Dropdown className="d-inline-block me-2">
            <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-auto" style={{ minWidth: '246px' }}>
              {nameConcept}
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow " style={{ maxHeight: '250px', overflowY: 'scroll', fontFamily: 'Montserrat' }}>
              {chargeConceptList.length > 0 &&
                chargeConceptList.map((element, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => {
                      setNameConcept(element.name_concept);
                      setCodeConcept(element.code_concept);
                    }}
                  >
                    {element.name_concept}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>

          <div className="d-flex align-items-center justify-content-center">
            <Form.Control
              type="text"
              style={{ fontFamily: 'Montserrat', maxWidth: '90px', minWidth: '70px' }}
              className="nav-shadow me-2 my-3 my-sm-0"
              placeholder="$0"
              value={chargeAmount}
              onChange={(e) => {
                updateField(e.target.value);
              }}
            />
            <Button
              variant="primary"
              className="w-sm-auto"
              // style={{ maxWidth: '125px' }}
              onClick={type === 'increase' ? handlerIncreaseBalance : handlerAddCharges}
            >
              Aplicar
            </Button>
          </div>
        </div>
        <div className="text-primary text-medium">{error}</div>
      </Modal.Footer>
    </Modal>
  );
};

export default BalanceModal;
