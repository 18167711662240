import { createSlice } from '@reduxjs/toolkit';
//  shippingData is the information returned by the parcel once the shipment has been made.
const initialState = {
  shippingData: {},
  // shippingDetailID: '',
  labelIdForPickup: '',
  paginate: {
    pageSize: 10,
    currentPage: 1,
  },
};

const shippingDataSlice = createSlice({
  name: 'shippingData',
  initialState,
  reducers: {
    setShippingData(state, action) {
      state.shippingData = action.payload;
    },
    // setShippingDetail(state, action) {
    //   state.shippingDetailID = action.payload;
    // },
    setPaginate(state, action) {
      const { point, data } = action.payload;
      state.paginate[point] = data;
    },
    setLabeIdForPickup(state, action) {
      state.labelIdForPickup = action.payload;
    },
  },
});
// setShippingDetail,
export const { setShippingData, setPaginate, setLabeIdForPickup } = shippingDataSlice.actions; // imported in ----.js
const shippingDataReducer = shippingDataSlice.reducer; // store.js set up

export default shippingDataReducer;
