import React from 'react';
import { Row, Col } from 'react-bootstrap';

const RowBilling = () => {
  return (
    <Row className="g-0 mb-2 ps-4 pe-4 d-none d-lg-flex">
      <Col lg="1" className="d-flex flex-column mb-lg-0 pe-3">
        <div className="text-muted text-small">FOLIO</div>
      </Col>
      <Col lg="2" className="d-flex flex-column justify-content-center align-items-center pe-1">
        <div className="text-muted text-small">FECHA</div>
      </Col>
      <Col lg="4" className="d-flex flex-column justify-content-center pe-1">
        <div className="text-muted text-small">RAZON SOCIAL</div>
      </Col>
      <Col lg="2" className="d-flex flex-column justify-content-center align-items-center pe-1">
        <div className="text-muted text-small">MONTO</div>
      </Col>
      <Col lg="1" className="d-flex flex-column justify-content-center pe-1">
        <div className="text-muted text-small">ESTATUS</div>
      </Col>
    </Row>
  );
};

export default RowBilling;
